import React from 'react'
import axios from 'axios';
import { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@mui/material';
import { useParams, useHistory } from "react-router-dom";

const Addempoffer = () => {

  let history = useHistory();

  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [clientName, setClientName] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [ctc, setCtc] = useState("");

  const handleSubmit = (event) => {

    console.log(name, position, clientName, joiningDate, ctc);

    axios.post('https://employee.shinelogics.com/api/offer/save', {

      name: name,
      position: position,
      clientName: clientName,
      joiningDate: joiningDate,
      ctc: ctc,

    })
      .then(() => {

        toast.success("Submitted Successfully", { position: toast.POSITION.BOTTOM_CENTER },setTimeout(()=>{
          history.push('/offeremp')},2000));


      })
      .catch(() => {
        toast.error("You left somefield empty", { position: toast.POSITION.BOTTOM_CENTER });
      })

    event.preventDefault();

  }


  return (
      <div className="user">
        <form onSubmit={handleSubmit} >
          <div class="form-row">
            <div class="col">
            <h5>Name</h5>
              <TextField type='text' className='textfiel' required id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
            </div>
            <div className="col">
            <h5>Position</h5>
              <TextField type='text' className='textfiel' required id="outlined-basic" label="position" variant="outlined" value={position} onChange={(event) => setPosition(event.target.value)} />
            </div>
          </div>
          <br />
          <div class="form-row">
            <div class="col">
              <h5>ClientName</h5>
              <TextField className='textfiel' id="outlined-basic"  label="clientName" type='text' variant="outlined" value={clientName} onChange={(event) => setClientName(event.target.value)} />
            </div>
            <div className="col">
            <h5>JoiningDate</h5>
              <TextField className='textfiel' id="outlined-basic" required label="" type='date' variant="outlined" value={joiningDate} onChange={(event) => setJoiningDate(event.target.value)} />
            </div>
          </div>
          <br />
          <div class="form-row">
            <div class="col">
            <h5>Ctc</h5>
              <TextField className='textfiel' id="outlined-basic" label="ctc" variant="outlined" value={ctc} onChange={(event) => setCtc(event.target.value)} />
            </div>
            <div className="col">
            <button className="btn btn-primary">
            Create
          </button>
            <ToastContainer />
            </div>
          </div>
          <br />
        </form>
      </div>
  )
}

export default Addempoffer