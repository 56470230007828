import React, { useState, useEffect } from "react";
import pdfmakedownload from "./PdfContainer";
import "./invoice.css";
import TextField from "@mui/material/TextField";
import { ratingClasses } from "@mui/material";
import axios from "axios";
import { BsTrash } from "@react-icons/all-files/bs/BsTrash";
import { Button } from "@material-ui/core";

const Invoice = () => {
  const [searchterm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);


  // Number onKeyPress//////
  const number = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  const divStyle = {
    overflowX: "auto",
  };

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [workType, setWorkType] = useState();
  const [hours, setHours] = useState();
  const [date, setDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [amountType, setAmountType] = useState();
  const [cgst, setCgst] = useState();
  const [sgst, setSgst] = useState();
  const [taxothers, setTaxothers] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [newData, setNewData] = useState();
  const [finest, setFinest] = useState();
  const [currencySymbol, setCurrencySymbol] = useState();

  const [employee, setEmployee] = useState([
    {
      name: "",
      date: "",
      nodw: "",
      nodo: "",
      workType: "",
      hours: 0,
      rate: 0,
      totalValue: 0,
      totalDays: 0,
    },
  ]);

  let handleChange = (i, e) => {
    employee[i][e.target.name] = e.target.value;
    //  Value calculation

    employee.forEach((e) => {
      e.totalValue = (e.nodw - e.nodo) * e.rate * e.hours;
    });

    const totalAmount = employee.reduce((total, e) => total + e.totalValue, 0);

    console.log(totalAmount,"qqqqqqqqqqqqq");
    setTotalAmount(totalAmount)
    setEmployee([...employee]);
  };

  let addFormFields = () => {
    setEmployee([
      ...employee,
      {
        name: "",
        month: "",
        nodw: "",
        nodo: "",
        workType: "",
        hours: 0,
        rate: 0,
        totalValue: 0,
      },
    ]);
  };



  let removeFormFields = (i) => {
    let newForm = [...employee];
    newForm.splice(i, 1);
    setEmployee(newForm);
  };

  const handleSubmit = (event) => {
    let totalDays = [];

    let result = employee.filter((x) => {
      let val = x.nodw - x.nodo;
      totalDays.push(val);
    });

    let addTotal = employee.map(function (x, index) {
      return totalDays[index] * x.rate;
    });

    let totalValue = addTotal.reduce((acc, x) => {
      return acc + x;
    });

    let total1 = totalValue * hours;

    let cgstValue = (total1 / 100) * cgst;
    let sgstValue = (total1 / 100) * sgst;

    let total = total1 + cgstValue + sgstValue;
    

    let splitOperation = date.split("-").reverse();

    let January =
      splitOperation[1] === "01"
        ? splitOperation.splice(1, 1, "January,")
        : "false";
    let February =
      splitOperation[1] === "02"
        ? splitOperation.splice(1, 1, "February,")
        : "false";
    let March =
      splitOperation[1] === "03"
        ? splitOperation.splice(1, 1, "March,")
        : "false";
    let April =
      splitOperation[1] === "04"
        ? splitOperation.splice(1, 1, "April,")
        : "false";
    let Mays =
      splitOperation[1] === "05"
        ? splitOperation.splice(1, 1, "May,")
        : "false";
    let June =
      splitOperation[1] === "06"
        ? splitOperation.splice(1, 1, "June,")
        : "false";
    let Julys =
      splitOperation[1] === "07"
        ? splitOperation.splice(1, 1, "July,")
        : "false";
    let August =
      splitOperation[1] === "08"
        ? splitOperation.splice(1, 1, "August,")
        : "false";
    let September =
      splitOperation[1] === "09"
        ? splitOperation.splice(1, 1, "September,")
        : "false";
    let October =
      splitOperation[1] === "10"
        ? splitOperation.splice(1, 1, "October,")
        : "false";
    let November =
      splitOperation[1] === "11"
        ? splitOperation.splice(1, 1, "November,")
        : "false";
    let December =
      splitOperation[1] === "12"
        ? splitOperation.splice(1, 1, "December,")
        : "false";

    let generateDate = splitOperation.join(" ");

    let dateFrom = fromDate.split("-").reverse().join("-");

    let dateTo = toDate.split("-").reverse().join("-");

    

    // let dateOfJ = doj.split('-').reverse().join('-');
    // console.log(dateTo);

    // let totalDays = nodw - nodo;
    // let totalAmount = totalDays * rate;

    // let cgstValue = totalAmount / 100 * cgst;

    // let sgstValue = totalAmount / 100 * sgst;

    // let roundValue = totalAmount
    // var decimals = roundValue - Math.floor(roundValue);
    // let roundOff = decimals.toFixed(1);

    // let total = Math.round(totalAmount + cgstValue + sgstValue);

    // let nodwCalc = nodw + ' ' + workType
    // console.log('nodw', nodwCalc);
    // console.log('nodo', nodo);

    // let totalDay = totalDays + ' ' + workType
    // console.log('totalDays', totalDay);

    // let totalAmounts = amountType + ' ' + totalAmount
    // console.log('totalAmount', totalAmounts);

    // let rates = amountType + ' ' + rate
    // console.log('rate', rates);

    // console.log('cgstValue', cgstValue);
    // console.log('sgstValue', sgstValue);
    // console.log('roundOff', roundOff);

    // let totalValue = amountType + ' ' + total
    // console.log('total', totalValue);

    event.preventDefault();

    axios
      .post("https://employee.shinelogics.com/api/invoice/save", {
        from: from,
        to: to,
        workType: workType,
        generateDate: generateDate,
        dateFrom: dateFrom,
        dateTo: dateTo,
        cgst: cgst,
        sgst: sgst,
        taxothers: taxothers,
        // amountType: amountType,
        amountType: currencySymbol,
        employee: employee,
        totalDays: totalDays,
        addTotal: addTotal,
        amount: totalAmount,
        cgstValue: cgstValue,
        sgstValue: sgstValue,
      })
      .then((response) => {
        setNewData(response.data);
        console.log("newdata", response);
        pdfmakedownload(response.data, response.data.employee);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect((_id) => {
    // let date1 = new Date()
    // console.log(data,'eeeeeeeeeeeeeeeeeeeeeeeeeeee');
    // axios.get(`https://employee.shinelogics.com/api/invoice/getById/:id`)
    //     .then((response) => {
    //         setData(response.data)
    //     })
    //     .catch(() => {
    //         console.log("ERR");
    //     })
  });

  {
    /* <button class="btn btn-outline-primary"
        onClick={() => {
          pdfmakedownload();
        }}
      >
        Download
      </button> */
  }

  return (
    // <div className='invoicehead'>
    //   <h3 >Invoice Generation</h3>
    // </div>
    <div className="user">
      <div className="card">
        <form onSubmit={handleSubmit}>
          <div id="comp">
            <div class="form-row">
              {/* <div class="col">
            <h5>From Address</h5>
            <TextField type='text' className='textfiel' multiline rows={4} id="outlined-basic" label="From Address" variant="outlined" value={from} onChange={(event) => setFrom(event.target.value)} />
          </div> */}
              {/* <div className="col">
            <h5>To Address</h5>
            <TextField type='text' className='textfiel' multiline rows={4} id="outlined-basic" label="To Address" variant="outlined" value={to} onChange={(event) => setTo(event.target.value)} />
          </div> */}
              {/* <div class="col">
            <h5>Work Type</h5>
            <select className="selects"
              value={workType}
              onChange={(e) => setWorkType(e.target.value)} >
              <option >--Select the type--</option>
              <option value="days">day</option>
              <option value="hours">hour</option>
              <option value="task">task</option>
            </select>
          </div> */}
            </div>
            <br />
            <div class="form-row">
              <div className="col">
                <h5>To Address</h5>
                <TextField
                  type="text"
                  className="textfiel"
                  multiline
                  rows={4}
                  id="outlined-basic"
                  label="To Address"
                  variant="outlined"
                  value={to}
                  onChange={(event) => setTo(event.target.value)}
                />
              </div>

              {/* <div class="col">
            <h5>Hours</h5>
            <TextField className='textfiel' id="outlined-basic" label="Hours" variant="outlined" type='number' value={hours} onChange={(event) => setHours(event.target.value)} />
          </div> */}
              <div className="col">
                <h5>Generate Date</h5>
                <TextField
                  style={{ border: "5pxsolidred" }}
                  className="textfiel"
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  type="date"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col">
                <h5>From date</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label=""
                  type="date"
                  variant="outlined"
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                />
              </div>
              <div className="col">
                <h5>To Date</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  type="date"
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col">
                <h5>SGST</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label="SGST"
                  variant="outlined"
                  value={sgst}
                  onChange={(event) => setSgst(event.target.value)}
                  onKeyPress={number}
                />
              </div>
              <div className="col">
                <h5>CGST</h5>
                <TextField
                  className="textfiel"
                  id="outlined-basic"
                  label="CGST"
                  variant="outlined"
                  value={cgst}
                  onChange={(event) => setCgst(event.target.value)}
                  onKeyPress={number}
                />
              </div>
            </div>
            <br />

            <div class="form-row">
              {/* <div class="col">
                <h5>Amount Type</h5>
                <select
                  className="selects"
                  value={amountType}
                  onChange={(e) => setAmountType(e.target.value)}
                >
                  <option>--Select the type--</option>
                  <option value="₹">Rupees</option>
                  <option value="$">Dollar</option>
                </select>
              </div> */}
               <div class="col">
              <h5>Currency Symbol</h5>
              <select
                className="selects"
                value={currencySymbol}
                onChange={(e) => setCurrencySymbol(e.target.value)}
              >
                <option>--Select the type--</option>
                <option value="₹">Rupees</option>
                <option value="$">Dollar</option>
              </select>
            </div>
                <div class="col">
                  <h5>Tax Others</h5>
                  <TextField
                    className="textfiel"
                    id="outlined-basic"
                    label="Taxothers"
                    variant="outlined"
                    type="number"
                    value={taxothers}
                    onChange={(event) => setTaxothers(event.target.value)}
                  />
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            {employee.map((element, index) => (
              <div key={index}>
                <div class="form-row">
                  <div class="col">
                    <h5>Name</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      name="name"
                      value={element.name || ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                  <div className="col">
                    <h5>Date of Joining</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      type="month"
                      name="date"
                      // value={element.month || ""}
                      value={element.date|| ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
                <br />
                <div class="form-row">
                  <div class="col">
                    <h5>Number of Days/hours Worked</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="Number of Days Worked"
                      variant="outlined"
                      name="nodw"
                      value={element.nodw || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                  <div className="col">
                    <h5>No of Days Off</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="No of Days Off"
                      variant="outlined"
                      name="nodo"
                      value={element.nodo || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                </div>
                <br />
                <div class="form-row">
                  <div class="col">
                    <h5>Rate</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="Rate"
                      variant="outlined"
                      name="rate"
                      value={element.rate || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                  <div class="col">
                    <h5>Work Type</h5>
                    <select
                      className="selects"
                      value={workType}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option>--Select the type--</option>
                      <option value="days">day</option>
                      <option value="hours">hour</option>
                      {/* <option value="task">task</option> */}
                    </select>
                  </div>
                </div>
                <br />
                <div class="form-row">
                  <div class="col">
                    <h5>Hours</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="Hours"
                      variant="outlined"
                      name="hours"
                      value={element.hours || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                  <div class="col">
                    <h5>TotalValue</h5>
                    <TextField
                      className="textfiel"
                      id="outlined-basic"
                      label="TotalValue"
                      variant="outlined"
                      name="totalValue"
                      value={element.totalValue || ""}
                      onChange={(e) => handleChange(index, e)}
                      onKeyPress={number}
                    />
                  </div>
                </div>
                <i
                  variant="contained"
                  type="button"
                  className="removebuttonone"
                  onClick={() => removeFormFields(index)}
                >
                  <BsTrash />
                </i>
              </div>
            ))}
            <Button
              variant="contained"
              className="firstbutton"
              onClick={() => addFormFields()}
            >
              Add
            </Button>
            <div className="col">
              <button className="btn btn-primary">Create</button>
            </div>
          </div>
        </form>
      </div>
      {/* <div style={divStyle}>
                            <table class="table border shadow">
                                <thead  class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" >Name</th>
                                        <th scope="col" >Position</th>
                                        <th scope="col" >Client Name</th>
                                        <th scope="col" >*CTC</th>
                                        <th scope="col" >*CTC</th>
                                        <th scope="col" >*CTC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.filter((val) => {
                                        if (searchterm === "") {
                                            return val
                                        }
                                        else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) {
                                                return val
                                        }
                                      
                                    }).map((val, index) => (
                                        <tr key={val.empId}>
                                            <td>{index + 1}</td>
                                            <td>{val.name}</td>
                                            <td>{val.position}</td>
                                            <td>{val.clientName}</td>
                                            <td>{val.ctc}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> */}
    </div>
  );
};

export default Invoice;
