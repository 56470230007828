import "./Master.css";
import Axios from 'axios';
import { useState,useEffect} from "react";
import { useParams, useHistory } from "react-router-dom";
import { TextField } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NewUser() {

  let history = useHistory();

  const [name, setName]=useState("");
  const [mobile, setMobile]=useState("");
  const [email,setEmail]=useState("");
  const [password,setPassword]=useState("");
  const [listOfFriends, setListOfFriends]=useState([]);

    const handleSubmit =(event) =>{
      Axios.post('https://employee.shinelogics.com/api/user/save',{
        name:name,
        mobile:mobile,
        email:email,
        password:password,
    })
    .then(() => {
  
      toast.success("Submitted Successfully", { position: toast.POSITION.BOTTOM_CENTER },
      setTimeout(()=>{
        history.push('/users')},2000));


    })
    .catch(() => {
      toast.error("You left somefield empty", { position: toast.POSITION.BOTTOM_CENTER });
    })
    event.preventDefault();

    

  }
    useEffect(()=>{
      Axios.get('https://employee.shinelogics.com/api/user/get')
      .then((response)=>{
        setListOfFriends(response.data)
      })
      .catch(()=>{
        console.log("ERR");
      })
    },[])
    
  return (
    <div className='user'>
      {/* <h4>Create New User</h4> */}
      <form onSubmit={handleSubmit}>
        <div class="form-row">
          <div class="col">
            <h5>Name</h5>
            <TextField type='text'className='textfiel' id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
          </div>
          <div className="col">
            <h5>Email</h5>
            <TextField type='text' className='textfiel' id="outlined-basic" label="Email" variant="outlined" value={email} onChange={(event) => setEmail(event.target.value)} />
          </div>
        </div>
        <br />
        {/* <div class="form-row">
          <div class="col">
            <h5>Password</h5>
            <TextField className='textfiel' id="outlined-basic" label="Password" variant="outlined" value={password} onChange={(event) => setPassword(event.target.value)} />
          </div>
          <div className="col">
            <h5>Mobile</h5>
          <TextField className='textfiel' id="outlined-basic" label="Mobile" variant="outlined" value={mobile} onChange={(event) => setMobile(event.target.value)} />
         
          </div>
        </div> */}
        <br/>
        <button className="btn btn-primary">
            Create
          </button>
          <ToastContainer/>
        <br />
      </form>
    </div>
  );
}
