import React from 'react';
import axios from 'axios';
import { useState,useEffect} from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@mui/material';
import { useParams, useHistory } from "react-router-dom";

const Addemployee = () => {


  let history = useHistory();

    const [empName, setEmpName]=useState("");
    const [email, setEmail]=useState("");
    const [password, setPassword]=useState("");
    const [empId, setEmpId]=useState("");
    const [date,setDate]=useState("");
    // const [totalWD,setTotalWD]=useState("");
    const [designation,setDesignation]=useState("");
    // const [nodw,setNodw]=useState("");
    const [department,setDepartment]=useState("");
    const [panNumber,setPanNumber]=useState("");
    const [dateOfJoining,setDateOfJoining]=useState("");
    const [accNo,setAccNo]=useState("");
    const [branch,setBranch]=useState("");
    const [esiNum,setEsiNum]=useState("");
    // const [salary,setSalary]=useState("");
    const [address,setAddress]=useState("");
    const [location,setLocation]=useState("");
    const [ctc,setCtc]=useState([]);
    const [listOfEmployees, setListOfEmployees]=useState([]);


    const handleSubmit = (event) => {

      /* let ctcPerAnnum = [];

      ctcPerAnnum.push({salaryCtc : ctc , date : date});
      console.log('ctcs',ctcPerAnnum); */

      let joiningDate = dateOfJoining.split('-').reverse().join('-');
    console.log('month#####', joiningDate);

      axios.post('https://employee.shinelogics.com/api/employee/save', {
  
        empName: empName, 
        email:email,
        password :password,
        empId : empId,
        date: date,
        // totalWD: totalWD,
        designation:designation,
        // nodw:nodw,
        department:department,
        panNumber:panNumber,
        dateOfJoining:dateOfJoining,
        joiningDate:joiningDate,
        accNo:accNo,
        branch:branch,
        esiNum:esiNum,
        // salary: salary,
        address:address,
        location:location,
        ctc: ctc
  
      })
        .then(() => {
  
          toast.success("Submitted Successfully", { position: toast.POSITION.BOTTOM_CENTER },
          setTimeout(()=>{
            history.push('/employee')
            },2000));
        })
        .catch(() => {
          toast.error("You left somefield empty", { position: toast.POSITION.BOTTOM_CENTER });
        })
  
  
      event.preventDefault();
      console.log(empName,empId,date,designation,department,panNumber,joiningDate,
        accNo,branch,esiNum,location,ctc,address);
        
        
      
    }

    
  return (
    <div className='user'>
      <form onSubmit={handleSubmit}>
        <div class="form-row">
          <div class="col">
            <h5>Name</h5>
            <TextField type='text'className='textfiel' id="outlined-basic" label="Name" variant="outlined" value={empName} onChange={(event) => setEmpName(event.target.value)} />
          </div>
          <div className="col">
          <h5>Email</h5>
            <TextField type='text' className='textfiel' id="outlined-basic" label="Email" variant="outlined" value={email} onChange={(event) => setEmail(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
          <h5>Password</h5>
            <TextField className='textfiel' id="outlined-basic" label="Password" variant="outlined" value={password} onChange={(event) => setPassword(event.target.value)} />
          </div>
          <div className="col">
          <h5>EmpId</h5>
            <TextField className='textfiel' id="outlined-basic" label="empId" variant="outlined" value={empId} onChange={(event) => setEmpId(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
          <h5>Date</h5>
            <TextField className='textfiel' id="outlined-basic" label=""  type='date' variant="outlined" value={date} onChange={(event) => setDate(event.target.value)} />
          </div>
          <div className="col">
          <h5>Designation</h5>
            <TextField className='textfiel' id="outlined-basic" label="designation" variant="outlined" value={designation} onChange={(event) => setDesignation(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
          <h5>Department</h5>
            <TextField className='textfiel' id="outlined-basic" label="department" variant="outlined" value={department} onChange={(event) => setDepartment(event.target.value)} />
          </div>
          <div className="col">
          <h5>PanNumber</h5>
            <TextField className='textfiel' id="outlined-basic" label="panNumber" variant="outlined" value={panNumber} onChange={(event) => setPanNumber(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
          <h5>DateOfJoining</h5>
            <TextField className='textfiel' id="outlined-basic" type='date' label="" variant="outlined" value={dateOfJoining} onChange={(event) => setDateOfJoining(event.target.value)} />
          </div>
          <div className="col">
          <h5>AccNo</h5>
            <TextField className='textfiel' id="outlined-basic"  type='number' label="accNo" variant="outlined" value={accNo} onChange={(event) => setAccNo(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
          <h5>Branch</h5>
            <TextField className='textfiel' id="outlined-basic"  label="branch" variant="outlined" value={branch} onChange={(event) => setBranch(event.target.value)} />
          </div>
          <div className="col">
          <h5>EsiNum</h5>
            <TextField className='textfiel' id="outlined-basic"  label="esiNum" variant="outlined" value={esiNum} onChange={(event) => setEsiNum(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
          <h5>Location</h5>
            <TextField className='textfiel' id="outlined-basic" label="location" variant="outlined" value={location} onChange={(event) => setLocation(event.target.value)} />
          </div>
          <div className="col">
          <h5>Ctc</h5>
            <TextField className='textfiel' id="outlined-basic"  label="ctc" variant="outlined" value={ctc} onChange={(event) => setCtc(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
          <h5>Address</h5>
            <TextField className='textfiel' id="outlined-basic"  label="address" variant="outlined" value={address} onChange={(event) => setAddress(event.target.value)} />
          </div>
          <div class="col">
          <button className="btn btn-primary">
            Create
          </button>
          <ToastContainer/>
          </div>
        </div>
        <br />
      </form>
    </div>
  )
};

export default Addemployee;