import React from "react";
import Logo from "../../assets/logo.png"
import "./topbar.css";
// import { NotificationsNone/* , Language, Settings */ } from "@material-ui/icons";
 import Avatar from '../../assets/avatar.png'
 import { useState,useRef } from "react";
 import { Overlay,Tooltip } from "react-bootstrap";



export default function Topbar() {

  const [show, setShow] = useState(false);
  const target = useRef(null);
  // const [notification, setNotification] = useState(false);
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <span className="logo"><img src={Logo} alt="" to="/login" /></span>
        </div>
        <div className="topRight">
          {/* <div className="topbarIconContainer">
            <NotificationsNone ref={target} onClick={() => setNotification(!notification)}/>
            <Overlay target={target.current} show={notification} placement="bottom">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  <p>No Notifications</p>
                </Tooltip>
              )}
            </Overlay>
            <span className="topIconBadge">2</span>
          </div> */}
          {/* <div className="topbarIconContainer">
            <Language />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Settings />
          </div> */}
         <img src={Avatar } alt="" ref={target} onClick={() => setShow(!show)} height={30} width={30}/> 
         <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            <a href="/" className="signout"> signout </a>
          </Tooltip>
        )}
      </Overlay>

        </div>
      </div>
    </div>
  );
}
