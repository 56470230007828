import React from 'react' 
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { TextField } from '@mui/material';
import Axios from 'axios';

const Update = () => {

    const { id } = useParams();

    let history = useHistory();
    
    const [data, setData] = useState("");
  
    const [paymentTypen, setPaymentTypen] = useState("");
    const [paymentNamen, setPaymentNamen] = useState("");
    const [paymentPercentagen, setPaymentPercentagen] = useState("");


    useEffect(()=>{
        Axios.get(`https://employee.shinelogics.com/api/payment/getid/${id}`,data)
        .then((response) => { setData(response.data);
            setPaymentTypen(response.data.paymentType);
            setPaymentNamen(response.data.paymentName);
            setPaymentPercentagen(response.data.paymentPercentage);
        })   
    },[])

    
  const handleSubmit = (event) => {
    
    Axios.put(`https://employee.shinelogics.com/api/payment/update/${id}`,{paymentType:paymentTypen,paymentName:paymentNamen,paymentPercentage:paymentPercentagen})
    .then(()=>{
        setData(data.map((val)=>{
          return val._id === id
           ? {_id:id, paymentType:paymentTypen,paymentName:paymentNamen,paymentPercentage:paymentPercentagen}
           : val;
        }))
      })
      history.push('/master');
}


  return (
    <div className='user'>
    <form onSubmit={handleSubmit}>
      <div class="form-row">
        <div class="col">
          <TextField className='textfiel' type='text' id="outlined-basic" label="paymentType" variant="outlined" value={paymentTypen} onChange={(event) => setPaymentTypen(event.target.value)} />
        </div>
        <div className="col">
          <TextField className='textfiel' type='text' id="outlined-basic" label="paymentName" variant="outlined" value={paymentNamen} onChange={(event) => setPaymentNamen(event.target.value)} />
        </div>
      </div>
      <br />
      <div class="form-row">
        <div class="col">
          <TextField className='textfiel' type='number' id="outlined-basic"  label="paymentPercentage" variant="outlined" value={paymentPercentagen} onChange={(event) => setPaymentPercentagen(event.target.value)} />
        </div>
        <div class="col">
        <button /* type="button" class="btn btn-primary" */>
          Update
        </button>
        </div>
      </div>
      <br />

    </form>
  </div>
  )
}

export default Update