import React, { useState, useEffect } from 'react'
import { Rating } from 'react-simple-star-rating'
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillPlayFill } from "@react-icons/all-files/bs/BsFillPlayFill";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import Dropdown from './Dropdown';
import Popup from './Popup'

const Review = (props) => {

    let history = useHistory();
    const [billRating, setBillRating] = useState(0);
    const [billRating1, setBillRating1] = useState(0);
    const [attendance, setAttendance] = useState(0);
    const [attendance1, setAttendance1] = useState(0);
    const [skill, setSkill] = useState(0);
    const [skill1, setSkill1] = useState(0);
    const [leadership, setLeadership] = useState(0);
    const [attitude, setAttitude] = useState(0);
    const [accurancy, setAccurancy] = useState(0);
    const [ontime, setOntime] = useState(0);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [rName, setRName] = useState('');
    const [rPosition, setRPosition] = useState('');

    const { id } = useParams();

    const [data, setData] = useState([]);
    const [datas, setDatas] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [popupVal, setPopupVal] = useState([]);
  
    const togglePopup = (val) => {
      setIsOpen(!isOpen);
      setPopupVal(val);
    }

    useEffect(() => {
        axios.get(`https://employee.shinelogics.com/api/employee/getid/${id}`, datas)
            .then((response) => {
                setDatas(response.data);
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])
  
   console.log('status',datas);

//    const func = axios.get('https://employee.shinelogics.com/api/appraisal/getAppraisal')
//    .then((response) => {
//     setData(response);
// })
// .catch(() => {
//     console.log("ERR");
// })



    const handleSubmit = (event) => {
        event.preventDefault();
        let arr = [];
        arr.push(billRating, billRating1, attendance, attendance1, skill, skill1, leadership, attitude, accurancy, ontime);
        console.log('arr', arr);

        let result = arr.reduce((acc, val) => {
            return acc + val;
        })

        let newHikePercentage = result / 10
        console.log('final', newHikePercentage);

        let slap = ''

        if (newHikePercentage <= 50) {
            slap += 'I';
        }
        else if (newHikePercentage <= 75) {
            slap += 'II'
        }
        else {
            slap += 'III'
        }

        console.log('slap', slap);

        console.log('datas', datas);


        axios.post('https://employee.shinelogics.com/api/appraisal/save', {
            rName: rName,
            rPosition: rPosition,
            newHikePercentage: newHikePercentage,
            fromDate: fromDate,
            toDate: toDate,
            slap: slap,
            datas: datas
        })
            .then(() => {

                toast.success("your review has been submitted", { position: toast.POSITION.BOTTOM_CENTER },
                    setTimeout(() => {
                        window.location.reload(false)
                    }, 4000));
            })
            .catch(() => {
                toast.error("Error", { position: toast.POSITION.BOTTOM_CENTER });
            })
    }



    return (
        <div className='user'>
            <div className='card'>
                <h4>Employee Perfomance Review</h4>
                <br />
                <div className="userShowInfo">
                    <div className='firstrow'>
                        <h5><BsFillPlayFill /> Name:</h5>
                        <h5><BsFillPlayFill /> EmpID:</h5>
                        <h5><BsFillPlayFill /> Email:</h5>
                    </div>
                    <div className='secondrow'>
                        <h5>{datas.empName}</h5>
                        <h5>{datas.empId}</h5>
                        <h5>{datas.email}</h5>
                    </div>
                    {/* <div className='thirdrow'>
                    <h5><BsFillPlayFill /> Pre. Inc Perc:</h5>
                    <h5><BsFillPlayFill /> Slap:</h5>
                    <h5><BsFillPlayFill /> Date:</h5>
                </div>
                <div className='fourthrow'>
                    <h5>{data.newHikePercentage+'%'}</h5>
                    <h5>{data.slap}</h5>
                    <h5>{data.fromDate+' '+'to'+' '+data.toDate}</h5>
                </div> */}
                </div>
                <hr />
                <form onSubmit={handleSubmit}>
                    <br />
                    <h4>Reviewing person details</h4>
                    <div class="form-row">
                        <div class="col" >
                            <TextField style={{ width: '40%' }} type='text' className='textfiel' id="standard-basic" label="Name" variant="standard" value={rName} onChange={(event) => setRName(event.target.value)} />
                        </div>
                        <div class="col" >
                            <TextField style={{ width: '40%' }} type='text' className='textfiel' id="standard-basic" label="Position" variant="standard" value={rPosition} onChange={(event) => setRPosition(event.target.value)} />
                        </div>
                    </div>
                    <br />
                    <div class="form-row">
                        <div class="col" >
                            <h4 style={{ color: '#8c8c8c' }}>Billing Price</h4>
                        </div>
                        <div class="col" >
                            <h4 style={{ color: '#8c8c8c' }}>Attentance</h4>
                        </div>
                    </div>
                    <div class="form-row" style={{ marginTop: '30px' }}>
                        <div class="col" >
                            <h5>Billing Price</h5>
                            <Rating
                                onClick={(e) => setBillRating(e)}
                                ratingValue={billRating}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                        <div class="col">
                            <h5>Client Appraisal</h5>
                            <Rating
                                onClick={(e) => setBillRating1(e)}
                                ratingValue={billRating1}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                        <div class="col">
                            <h5>Regular</h5>
                            <Rating
                                onClick={(e) => setAttendance(e)}
                                ratingValue={attendance}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                        <div class="col">
                            <h5>Overtime</h5>
                            <Rating
                                onClick={(e) => setAttendance1(e)}
                                ratingValue={attendance1}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <div class="form-row">
                        <div class="col" >
                            <h4 style={{ color: '#8c8c8c' }}>Skill Ability</h4>
                        </div>
                        <div class="col" >
                            <h4 style={{ color: '#8c8c8c' }}>Leadership/Attitude</h4>
                        </div>
                    </div>
                    <br />
                    <div class="form-row">
                        <div class="col">
                            <h5>Technical</h5>
                            <Rating
                                onClick={(e) => setSkill(e)}
                                ratingValue={skill}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                        <div class="col">
                            <h5>Communication</h5>
                            <Rating
                                onClick={(e) => setSkill1(e)}
                                ratingValue={skill1}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                        <div class="col">
                            <h5>leadership</h5>
                            <Rating
                                onClick={(e) => setLeadership(e)}
                                ratingValue={leadership}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                        <div class="col">
                            <h5>Attitude</h5>
                            <Rating
                                onClick={(e) => setAttitude(e)}
                                ratingValue={attitude}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>

                    </div>
                    <br />
                    <br />
                    <div class="form-row">
                        <div class="col" >
                            <h4 style={{ color: '#8c8c8c' }}>Accurancy/Ontime delivery</h4>
                        </div>
                        <div class="col" >
                            <h4 style={{ color: '#8c8c8c' }}>Date</h4>
                        </div>
                    </div>
                    <br />
                    <div class="form-row">
                        <div class="col">
                            <h5 >Accurancy</h5>
                            <Rating
                                onClick={(e) => setAccurancy(e)}
                                ratingValue={accurancy}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                        <div class="col">
                            <h5>Ontime delivery</h5>
                            <Rating
                                onClick={(e) => setOntime(e)}
                                ratingValue={ontime}
                                size={20}
                                label
                                transition
                                fillColor='orange'
                                emptyColor='gray'
                                className='foo'
                            />
                        </div>
                        <div className="col">
                            <h5>From Date</h5>
                            <br />
                            <TextField className='textfiel' id="outlined-basic" label="" type='date' variant="outlined" value={fromDate} onChange={(event) => setFromDate(event.target.value)} />
                        </div>
                        <div className="col">
                            <h5>To Date</h5>
                            <br />
                            <TextField className='textfiel' id="outlined-basic" label="" type='date' variant="outlined" value={toDate} onChange={(event) => setToDate(event.target.value)} />
                        </div>
                    </div>
                    <br />
                    <div class="form-row">
                    </div>
                    <br />
                    <div class="form-row">
                        <div className="col">

                            <button className="btn btn-primary">
                                Create
                            </button>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                    <ToastContainer />
                </form>
                <div class="form-row">
                    <div className="col">
                    </div>
                    <div className="col">
                    </div>
                    <div className="col">
                    </div>
                    <div className="col">
                    {isOpen && <Popup payment={popupVal}
                    content={
                      <>
                        <Dropdown payment={popupVal} />
                      </>
                    }
                    handleClose={() => togglePopup({})}
                  />}
                    <button style={{float:'right',position:'relative',bottom:'30px'}} className="btn btn-primary"
                    onClick={() => togglePopup(datas)}>
                            View
                        </button>
                    </div>
                </div>

                <hr />
                {/* <div className="userlist">
                <div className="container">
                    <div className="py-4">
                        <div >
                            <table class="table border shadow">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" >Emp.Name</th>
                                        <th scope="col" >Hike per.</th>
                                        <th scope="col" >Slap</th>
                                        <th scope="col" >Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    { arr.length === 0 ?  null : arr.map((val,index) => (
                                        <tr key={val.empId}>
                                            <td>{index + 1}</td>
                                            <td>{val.empName}</td>
                                            <td>{val.newHikePercentage+'%'}</td>
                                            <td>{val.slap}</td>
                                            <td>{val.fromDate+' '+'to'+' '+val.toDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> */}
            </div>
        </div>
    )
}

export default Review