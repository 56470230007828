import "./userList.css";
import { Link } from "react-router-dom"; 
import Axios from 'axios';
import { useState,useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

export default function UserList() {
  
  const [searchterm, setSearchTerm]= useState('')
  const [data, setData] = useState([]);

    useEffect((id)=>{
      Axios.get(`https://employee.shinelogics.com/api/user/get`)
      .then((response)=>{
        setData(response.data)
      })
      .catch(()=>{
        console.log("ERR");
      })
    },[])
    const loadUsers = async (id) => {
      const result = await Axios.get("https://employee.shinelogics.com/api/user/get");
      setData(result.data.reverse());
    };
    const deleteUser =(id) =>{
      Axios.put(`https://employee.shinelogics.com/api/user/delete/${id}`)
      .then(()=>{
        setData(data.filter((val)=>{
            return val._id != id;
        }))
    })
      
    };
return(
  <>
  
    <div className="container">
    <div className='employeetop'>
                <h4>User Details</h4>
                <TextField type="search" variant="outlined" margin="normal" className='employeesearchbox' 
                onChange={(event)=>{setSearchTerm(event.target.value)}}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Link to="/newUser" className="userAdd">
                    <button type="button" className="btn btn-primary" id="userAddButtons">Create</button>
                </Link>
            </div> 
      <div className="userlist">
        <div className="container">
        <div className="py-4">
          <table class="table border shadow">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col" >Name</th>
                <th scope="col" >Mobile</th>
                <th scope="col" >Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.filter((val)=>{
                if(searchterm === ""){
                  return val
                }
                else if(val.name.toLowerCase().includes(searchterm.toLowerCase())){
                  return val
                }
              }).map((val, index) => (
                <tr>
                  <td>{index+1}</td>
                  <td>{val.name}</td>
                  <td>{val.mobile}</td>
                  <td>{val.email}</td> 
                  <td>
                    <Link
                      class="btn btn-outline-primary mr-2"
                      to={`/updateuser/${val._id}`}
                    >
                      Edit
                    </Link>
                    <button
                      class="btn btn-danger"
                      onClick={() => deleteUser(val._id)}
                    >Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>        
        </div>
      </div>
    </div>
    </div>
</>
  ); 
}
