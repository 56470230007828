import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import "./role.css"
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import { PermIdentity } from "@material-ui/icons";

const Role = () => {
  const [role, setRole] = useState("");
  const [searchterm, setSearchTerm] = useState("");
  const [modulename, setModuleName] = useState("");

  const divStyle = {
    overflowX: "auto",
  };

  const handleSubmit = () => {
    Axios.post("https://employee.shinelogics.com/api/role/save", {
      role: role,
      // modulename: modulename,
    })
      .then(() => {
        alert("ye it worked");
      })
      .catch(() => {
        alert("It didn't worked");
      });
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    Axios.get("https://employee.shinelogics.com/api/role/get", data)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setRole(response.data.role);
        setModuleName(response.data.modulename)
      })
      .catch(() => {
        console.log("ERR");
      });
  }, []);
  return (
    <div className="newUser">
      <h1 className="newUserTitle">Role</h1>
      <form onSubmit={handleSubmit} className="newUserForm">
        <div className="col-md-6">
          {/* <label for="UserName" className="control-label">
            Username
          </label> */}
          <TextField
            type="text"
            label="Role"
            className="textfiel"
            variant="outlined"
            onChange={(event) => setRole(event.target.value)}
          />
        </div>
        <div className="col-md-6">
          {/* <label for="UserName" className="control-label">
            Username
          </label> */}
          <TextField
            type="text"
            label="Module Name"
            className="textfiel"
            variant="outlined"
            onChange={(event) => setModuleName(event.target.value)}
          />
        </div>
        {/* <button className="newUserButton">Create</button> */}
        <div className="Buttons">
              <button className="btn btn-primary">Create</button>
            </div>
      </form>
      {/* <div>
      {data.map((data)=>{
        return(
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">{data.role}</span>
            </div>)})}
      </div> */}
      <div>
        <div className="container">
          <div className="employeetop">
            {/* <h4>Role</h4> */}
            {/* <TextField type="search" variant="outlined" margin="normal" className='employeesearchbox'
                 onChange={(event) => { setSearchTerm(event.target.value) }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                /> */}
          </div>
          {/* <div className="userlist">
            <div className="container">
              <div className="py-4">
                <div style={divStyle}>
                  <table class="table border shadow">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Modulename</th>
                        <th scope="col"> Status</th>
                        <th scope="col"> Edit</th>
                        <th scope="col"> Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter((data) => {
                          if (searchterm === "") {
                            return data;
                          } else if (
                            data.name
                              .toLowerCase()
                              .includes(searchterm.toLowerCase())
                          ) {
                            return data;
                          }
                        })
                        .map((data, index) => (
                          <tr key={data.empId}>
                            <td>{index + 1}</td>
                            <td>{data.role}</td>
                            <td>{data.modulename}</td>
                            
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Role;
