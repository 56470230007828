import React from 'react'
import Axios from 'axios';
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import './EmpView.css'
import Popupss from '../IncrementLetter/Popups';
import Dropdowns from '../IncrementLetter/Dropdown'
import Popups from '../OfferLetter/Popups'
import Dropdown from '../OfferLetter/Dropdown'
import pdfmakedownload from '../RelievingLetter/PdfContainer';
import image from '../../assets/dashboardicon.png'
import { BsFillPlayFill } from "@react-icons/all-files/bs/BsFillPlayFill";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { BsPhone } from "@react-icons/all-files/bs/BsPhone";
import { ImLocation } from "@react-icons/all-files/im/ImLocation";
import Dropdownss from '../RelievingLetter/DropDown'
import Popupsss from '../RelievingLetter/Popups';



const UserView = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/employee/getId/${id}`, data)
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])
    console.log("vvvvvvvvvvv", data);

    const [salary, setSalary] = useState([]);

    const func = () => {

        Axios.get(`https://employee.shinelogics.com/api/employee/getid/${id}`)
            .then(response => response.json())
            .then(salary => { setSalary(salary); console.log(salary); pdfmakedownload(salary); });
        console.log(salary);
    };
    console.log(salary);


    const [isOpens, setIsOpens] = useState(false);
    const [popupVals, setPopupVals] = useState([]);

    const togglePopups = (data) => {
        console.log("1111111111111111111111111111111111111111111111111111111111111");
        setIsOpens(!isOpens);
        setPopupVals(data);
    }
    const [isOpenss, setIsOpenss] = useState(false);
    const [popupValss, setPopupValss] = useState([]);

    const togglePopupss = (data) => {
        console.log("22222222222222222222222222222222222222222222222222222222222222");
        setIsOpenss(!isOpenss);
        setPopupValss(data);
    }

    const [isOpen, setIsOpen] = useState(false);
    const [popupVal, setPopupVal] = useState([]);

    const togglePopup = (data) => {
        console.log("333333333333333333333333333333333333333333333333333333333333333333");
        setIsOpen(!isOpen);
        setPopupVal(data);
    }

    const divStyle = {
        width: "75%"
    }

    return (

        <div>
            <div className="vuser">
                {/* <div className="userTitleContainer">
                    <h1 className="userTitle">Employee Details</h1>
                </div> */}
                <div className="userContainer">
                    <div className="userShow">
                        <div className="userShowTop">
                            <div className="userShowTopTitle">
                            <div className="topflex">
                                <div className='iconimage'>
                                    <img src={image} style={divStyle} alt='icon' />
                                </div>
                                <div className='topstatus'>
                                    <h2>{data.empName}</h2>
                                    <h5>{data.designation}</h5>
                                </div>
                                <div className='topcontact'>
                                    <h6><a href={"mailto:"+data.email}><AiOutlineMail/> Email</a></h6>
                                    <h6><BsPhone/> Phone</h6>
                                    <h6><ImLocation/> Chennai</h6>
                                </div>
                            </div>
                            </div>
                        </div>
                        <hr/>
                        {/* <div className='routing'>
                                <button className='routingfirst'>Personal Details</button>
                                <button className='routingsecond'>Letters</button>
                            </div> */}
                        <div className="userShowBottom">
                            <span className="userShowTitle">Personal Details</span>
                            <div className="userShowInfo">
                                <div className='firstrow'>
                                    <h5><BsFillPlayFill /> Name:</h5>
                                    <h5><BsFillPlayFill /> EmpID:</h5>
                                    <h5><BsFillPlayFill /> Email:</h5>
                                </div>
                                <div className='secondrow'>
                                    <h5>{data.empName}</h5>
                                    <h5>{data.empId}</h5>
                                    <h5>{data.email}</h5>
                                </div>
                                <div className='thirdrow'>
                                    <h5><BsFillPlayFill /> Designation:</h5>
                                    <h5><BsFillPlayFill /> Department:</h5>
                                    <h5><BsFillPlayFill /> Ctc Per Annum:</h5>
                                </div>
                                <div className='fourthrow'>
                                    <h5>{data.designation}</h5>
                                    <h5>{data.department}</h5>
                                    <h5>{data.ctc}</h5>
                                </div>
                            </div>
                            <br />
                            <hr/>
                            <span className="userShowTitle" id='letter'>Details</span>
                            <div className="userShowInfo">
                                <div className='fifthrow'>
                                    <h5>Increment Letter</h5>
                                    {/* <h5>Joining Letter</h5> */}
                                    <h5>Relieving Letter</h5>
                                    <h5>Employee Other Info & Project Details</h5>
                                    <h5>Employee Appraisal review</h5>
                                </div>
                                {isOpens && <Popupss payment={popupVals}
                                    content={
                                        <>
                                            <Dropdowns payment={popupVals} />
                                        </>
                                    }
                                    handleClose={() => togglePopups({})}
                                />}
                                {isOpenss && <Popupsss payment={popupValss}
                                    content={
                                        <>
                                            <Dropdownss payment={popupValss} />
                                        </>
                                    }
                                    handleClose={() => togglePopupss({})}
                                />}
                                {isOpen && <Popups payment={popupVal}
                                    content={
                                        <>
                                            <Dropdowns payment={popupVal} />
                                        </>
                                    }
                                    handleClose={() => togglePopup({})}
                                />}
                                <div className='sixthrow'>
                                    {/* <h5><button class="btn btn-outline-primary" onClick={() => {togglePopups(data)}} >Generate</button></h5> */}
                                    <h5><button  class="btn btn-outline-primary" onClick={() => {togglePopup(data)}}>Generate</button></h5>
                                    <h5><button class="btn btn-outline-primary" onClick={() => {togglePopupss(data)}}>Generate</button></h5>
                                    <h5><Link to={`/appraisal/${data._id}`}><button class="btn btn-outline-primary">Generate</button></Link></h5>
                                    <h5><Link to={`/review/${data._id}`}><button class="btn btn-outline-primary" >Generate</button></Link></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserView
