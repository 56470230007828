import React from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const EmployeeHistory = () => {

    const [searchterm, setSearchTerm] = useState('')
    const [data, setData] = useState([]);
    const [salary, setSalary] = useState([]);

    const divStyle = {
        overflowX: "auto"
    }
    const divTable = {
        borderSpacing: 0,
        width: 100,
        border: 1,
    }


    useEffect((_id) => {
        Axios.get(`https://employee.shinelogics.com/api/offer/getAllHistory`)
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])

    const deleteUser = (id) => {
        Axios.put(`https://employee.shinelogics.com/api/offer/delete/${id}`)
        .then(() => {
            setData(data.filter((val) => {
                return val._id != id;
            }))
        })
    };


  return (
    <div>
       <div className="container">
        <div className='employeetop'>
                <h4>Employee History</h4>
                <TextField type="search" variant="outlined" margin="normal" className='employeesearchbox'
                 onChange={(event) => { setSearchTerm(event.target.value) }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            <div className="userlist">
                <div className="container">
                    <div className="py-4">
                        <div style={divStyle}>
                            <table class="table border shadow">
                                <thead  class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" >Name</th>
                                        <th scope="col" >Position</th>
                                        <th scope="col" >Client Name</th>
                                        <th scope="col" >*CTC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.filter((val) => {
                                        if (searchterm === "") {
                                            return val
                                        }
                                        else if (val.name.toLowerCase().includes(searchterm.toLowerCase())) {
                                                return val
                                        }
                                      
                                    }).map((val, index) => (
                                        <tr key={val.empId}>
                                            <td>{index + 1}</td>
                                            <td>{val.name}</td>
                                            <td>{val.position}</td>
                                            <td>{val.clientName}</td>
                                            <td>{val.ctc}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}


export default EmployeeHistory
