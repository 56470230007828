import React from 'react'
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import Axios from 'axios';

const UpdateUser = () => {
    const { id } = useParams();
    let history = useHistory();

  const [data, setData] = useState([]);
  const [name, setName]=useState("");
  const [mobile, setMobile]=useState("");
  const [email,setEmail]=useState(""); 

    
    useEffect(()=>{
        Axios.get(`https://employee.shinelogics.com/api/user/getid/${id}`,data)
        .then((response)=>{
          setData(response.data) 
          setName(response.data.name);
          setEmail(response.data.email);
          setMobile(response.data.mobile); 
        })
        .catch(()=>{
          console.log("ERR");
        })
      },[])

      const handleSubmit =(event) =>{
        console.log("***********");
        event.preventDefault()
       Axios.put(`https://employee.shinelogics.com/api/user/update/${id}`, {name:name,email:email,mobile:mobile}).then(()=>{
         setData(data.map((val)=>{
           return val._id === id
            ? {_id:id, name:name, email:email, mobile:mobile}
            : val;
         }))
       })
       setTimeout(()=>{
       history.push('/users')},1000);
     
      }


    return (
        <div className='user'>
      <form onSubmit={handleSubmit}>
        <div class="form-row">
          <div class="col">
            <TextField type='text'className='textfiel' id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
          </div>
          <div className="col">
            <TextField type='text' className='textfiel' id="outlined-basic" label="Email" variant="outlined" value={email} onChange={(event) => setEmail(event.target.value)} />
          </div>
        </div>
        <br />
        <div class="form-row">
          <div class="col">
            <TextField className='textfiel' id="outlined-basic" label="Password" variant="outlined" value={mobile} onChange={(event) => setMobile(event.target.value)} />
          </div>
          <div className="col">
          <button className="btn btn-primary">
            Update
          </button>
       
          </div>
        </div>
        <br />
      </form>
    </div>
    )
}

export default UpdateUser