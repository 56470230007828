import React from 'react';
import { useState, useEffect } from "react";
import Popup from './Popup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './PaySlipmaster.css'
import Dropdown from './Dropdown';

const PaySlipmaster = () => {

    const [band, setBand] = useState('')

    const [table, setAddTable] = useState('');

    const [searchterm, setSearchTerm] = useState('')
    const [data, setData] = useState([]);
    const [order, setOrder] = useState("ASC")

    const divStyle = {
        overflowX: "auto"
    }
    const divTable = {
        borderSpacing: 0,
        width: 100,
        border: 1,
    }


    const sorting = (col) => {
        if (order === "ASC") {
            const sorted = [...data].sort((a, b) =>
                a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
            );
            setData(sorted);
            setOrder("DSC");
        }
        if (order === "DSC") {
            const sorted = [...data].sort((a, b) =>
                a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
            );
            setData(sorted);
            setOrder("ASC");
        }
    }

    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const payslipmaster = {
        marginTop: 50,
    }
    const masterheader = {
        // backgroundColor: "#e0ebeb",
        display: "flex"
    }
    const paybutton = {
        postition: "relative",
        marginLeft: "600px",
    }


    useEffect((id) => {
        axios.get(`https://employee.shinelogics.com/api/payment/get`)
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])
    const loadUsers = async (id) => {
        const result = await axios.get("https://employee.shinelogics.com/api/payment/get");
        // setData(result.data.reverse());
    };
    const deleteUser = (id) => {
        axios.put(`https://employee.shinelogics.com/api/payment/delete/${id}`)
            .then(() => {
                setData(data.filter((val) => {
                    return val._id != id;
                }))
            })
    };

    // event.preventDefault();
    //  console.log(paymentType+paymentName+paymentPercentage);



    const addTable = () => {
        console.log('ohhhhhhhhhhhh');
        setAddTable([...table] + 1)
    }

    let valu = data.filter(x => x.band === 'Band4')
    console.log('%%%%', valu);

    // let vals = [];
    //     let cals = valu.forEach(res => {
    //       vals.push(cals)
    //     })
    //     console.log(vals);

    console.log(band)


    return <div style={payslipmaster}>
        <div className='payslipmastertop' style={masterheader}>
            <h3>Payment Generation in master</h3>
            <input style={paybutton} className='btn btn-primary'
                type="button"
                value="create"
                onClick={togglePopup}
            />
        </div>

        {isOpen && <Popup
            content={
                <>
                    <Dropdown />
                </>}
            handleClose={togglePopup}
        />}
        <>
            <div className="container">
                <div className="userlist">
                    <div className="container">
                        <div className="py-4">
                            <div>
                                <table class="table border shadow">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">
                                                <select className="form-control"
                                                value={band}
                                                onChange={(e) => setBand(e.target.value)} >
                                                <option >--Select the band--</option>
                                                <option value="Band1">Band1</option>
                                                <option value="Band2">Band2</option>
                                                <option value="Band3">Band3</option>
                                                <option value="Band4">Band4</option>
                                                </select>
                                            </th>
                                            <th scope="col" >Payment Type</th>
                                            <th scope="col">Payment Name</th>
                                            <th scope="col" >Payment Percentage</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.filter((val) => {
                                            if (band === val.band) {
                                                return val
                                            }
                                            
                                        }).map((val, index) =>
                                            // val.band !== 'Band1' ?
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{val.band}</td>
                                                <td>{val.paymentType}</td>
                                                <td>{val.paymentName}</td>
                                                <td>{val.paymentPercentage}</td>
                                                <td>
                                                    <Link
                                                        class="btn btn-primary mr-2"
                                                        to={`/updateMaster/${val._id}`}
                                                    >
                                                        Edit
                                                    </Link>
                                                    <button
                                                        class="btn btn-danger"
                                                        onClick={() => deleteUser(val._id)}
                                                    >Delete
                                                    </button>
                                                </td>
                                            </tr>
                                       /* : null  */)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {table ?

                <div className="container">
                    <div className="userlist">
                        <div className="container">
                            <div className="py-4">
                                <table class="table border shadow">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Band</th>
                                            <th scope="col" >Payment Type</th>
                                            <th scope="col">Payment Name</th>
                                            <th scope="col" >Payment Percentage</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((value, index) =>
                                        ( value.band === 'Band1' ?

                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{value.band}</td>
                                                <td>{value.paymentType}</td>
                                                <td>{value.paymentName}</td>
                                                <td>{value.paymentPercentage}</td>
                                                <td>
                                                    <button
                                                        class="btn btn-danger"
                                                        onClick={() => deleteUser(value._id)}
                                                    >Delete
                                                    </button>
                                                </td>
                                            </tr>
                                       :null))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                : null} */}

            {/* <div id='toptable' >
                <h4 style={{marginLeft:50}}>Create a dulpicate</h4>
                <button onClick={addTable} className='btn btn-primary'>Add</button>
            </div> */}
            <br />
            <br />
            <br />
        </>

    </div>

}

export default PaySlipmaster;
