import React from 'react';
import './Employee.css'
import { Link } from "react-router-dom";
import Axios from 'axios';
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const Employee = (props) => {

    const { id } = useParams();
    let history = useHistory();

    console.log("aaaaaaaaa", props.payment);

    const [isOpen, setIsOpen] = useState(false);
    const [popupVal, setPopupVal] = useState([]);

    const [showEmployee, setShowEmployee] = useState([]);

    const togglePopup = (val) => {
        setIsOpen(!isOpen);
        setPopupVal(val);
    }


    const [searchterm, setSearchTerm] = useState('')
    const [data, setData] = useState([]);

    const divStyle = {
        overflowX: "auto"
    }
    const divTable = {
        borderSpacing: 0,
        width: 100,
        border: 1,
    }

    useEffect((id) => {
        Axios.get(`https://employee.shinelogics.com/api/employee/get`)
            .then((response) => {
                console.log("geeeeeeeeeeeeeeeeeeeeetttttttttttttttttttttttt")
                setData(response.data)
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])


    const deleteUser = (id) => {
        Axios.put(`https://employee.shinelogics.com/api/employee/delete/${id}`)
            .then(() => {
                setData(data.filter((val) => {
                    return val._id != id;
                }))
            })

    };

    const headStyle = {
        float: "right",
    }


    return <div> <>

        <div className="container">
            <div className='employeetop'>
                <h4>Employee Details</h4>
                <TextField type="search" variant="outlined" margin="normal" className='employeesearchbox'
                    onChange={(event) => { setSearchTerm(event.target.value) }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Link to="/newemployee" className="userAdd">
                    <button type="button" className="btn btn-primary" id="userAddButtons">Create</button>
                </Link>
            </div>
            <div className="userlist">
                <div className="container">
                    <div className="py-4">
                        <div style={divStyle}>
                            <table class="table border shadow">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" >Emp.Name</th>
                                        <th scope="col" >Emp.id</th>
                                        <th scope="col" >Designation</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.filter((val) => {
                                        if (searchterm === "") {
                                            return val
                                        }
                                        else if (val.empName.toLowerCase().includes(searchterm.toLowerCase())) {
                                            return val
                                        }
                                    }).map((val, index) => (
                                        <tr key={val.empId}>
                                            <td>{index + 1}</td>
                                            <td>{val.empName}</td>
                                            <td>{val.empId}</td>
                                            <td>{val.designation}</td>
                                            <td>
                                                <Link class="btn btn-primary mr-2" to={`/empview/${val._id}`}>
                                                    View
                                                </Link>
                                                <Link
                                                    class="btn btn-outline-primary mr-2"
                                                    to={`/updateEmp/${val._id}`}
                                                >
                                                    Edit
                                                </Link>
                                                <button
                                                    class="btn btn-danger"
                                                    onClick={() => deleteUser(val._id)}
                                                >Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    </div>;
};

export default Employee;
