import React, { useState, useEffect } from 'react'
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import axios, { Axios } from 'axios'
import pdfmakedownload from "../IncrementLetter/PdfContainer"
import TextField from '@mui/material/TextField';
import './Dropdown.css'




const Dropdown = (props, id) => {


  const [data, setData] = useState([])
  const [value, setvalue] = useState('')
  const [earningss, setEarnings] = useState();
  const [select, setSelect] = useState([])
  const [selects, setSelects] = useState([])
  const [newSalary, setNewSalary] = useState('')
  const [updatedSalary, setUpdatedSalary] = useState('')
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [date, setDate] = useState('')
  const [incrementDate, setIncrementDate] = useState('')


  const [salary, setSalary] = useState([]);
  const [newData, setNewData] = useState();
  const [finest, setFinest] = useState();


  const [band, setBand] = useState();

  const dropStyle = {
    height: "100%"
  }

  // console.log(props.payment);

  useEffect((id) => {
    axios.get(`https://employee.shinelogics.com/api/payment/get`)
      .then((response) => {
        setData(response.data)
        let details = [];
        details = response.data;

        let earning = details.filter(x => band === x.band ? x.paymentType == 'Earnings' : null);
        // console.log(earning);
        let values = []
        earning.forEach(res => {
          values.push({ label: res.paymentName, value: res.paymentName })
        })
        setEarnings(values);

      })
      .catch(() => {
        console.log("ERR");
      })
  }, [band])


  const handleSubmit = async (event) => {
    const arr = select.split(',');
    // console.log("arr", arr);
    const arrs = selects.split(',');
    // console.log("arr", arrs);
    console.log("newSalary", newSalary);
    console.log("from", from);
    console.log("to", to);
    event.preventDefault();

    console.log("date", date);

    /* --------------------------------------------- */

    let splitOperation = date.split('-').reverse();

    console.log('split first',splitOperation);

    let January = splitOperation[1] === '01' ? (splitOperation.splice(1, 1, 'January,')) : ('false');
    let February = splitOperation[1] === '02' ? (splitOperation.splice(1, 1, 'February,')) : ('false');
    let March = splitOperation[1] === '03' ? (splitOperation.splice(1, 1, 'March,')) : ('false');
    let April = splitOperation[1] === '04' ? (splitOperation.splice(1, 1, 'April,')) : ('false');
    let Mays = splitOperation[1] === '05' ? (splitOperation.splice(1, 1, 'May,')) : ('false');
    let June = splitOperation[1] === '06' ? (splitOperation.splice(1, 1, 'June,')) : ('false');
    let Julys = splitOperation[1] === '07' ? (splitOperation.splice(1, 1, 'July,')) : ('false');
    let August = splitOperation[1] === '08' ? (splitOperation.splice(1, 1, 'August,')) : ('false');
    let September = splitOperation[1] === '09' ? (splitOperation.splice(1, 1, 'September,')) : ('false');
    let October = splitOperation[1] === '10' ? (splitOperation.splice(1, 1, 'October,')) : ('false');
    let November = splitOperation[1] === '11' ? (splitOperation.splice(1, 1, 'November,')) : ('false');
    let December = splitOperation[1] === '12' ? (splitOperation.splice(1, 1, 'December,')) : ('false');

    let generateDate = splitOperation.join(' '); 

    console.log("gen date",generateDate);


    /* ------------------------------- */


    let startMonths = from.split('-').reverse();

    let Jan = startMonths[0] === '01' ? (startMonths.splice(0, 1, 'January')) : ('false');
    let Feb = startMonths[0] === '02' ? (startMonths.splice(0, 1, 'February')) : ('false');
    let Mar = startMonths[0] === '03' ? (startMonths.splice(0, 1, 'March')) : ('false');
    let Apr = startMonths[0] === '04' ? (startMonths.splice(0, 1, 'April')) : ('false');
    let May = startMonths[0] === '05' ? (startMonths.splice(0, 1, 'May')) : ('false');
    let Jun = startMonths[0] === '06' ? (startMonths.splice(0, 1, 'June')) : ('false');
    let July = startMonths[0] === '07' ? (startMonths.splice(0, 1, 'July')) : ('false');
    let Aug = startMonths[0] === '08' ? (startMonths.splice(0, 1, 'August')) : ('false');
    let Sep = startMonths[0] === '09' ? (startMonths.splice(0, 1, 'September')) : ('false');
    let Oct = startMonths[0] === '10' ? (startMonths.splice(0, 1, 'October')) : ('false');
    let Nov = startMonths[0] === '11' ? (startMonths.splice(0, 1, 'November')) : ('false');
    let Dec = startMonths[0] === '12' ? (startMonths.splice(0, 1, 'December')) : ('false');

    let startMonth = startMonths.join();

    console.log('start month', startMonth);

    /* ------------------------------------------------ */

    let endMonths = to.split('-').reverse();

    let jan = endMonths[0] === '01' ? (endMonths.splice(0, 1, 'January')) : ('false');
    let feb = endMonths[0] === '02' ? (endMonths.splice(0, 1, 'February')) : ('false');
    let mar = endMonths[0] === '03' ? (endMonths.splice(0, 1, 'March')) : ('false');
    let apr = endMonths[0] === '04' ? (endMonths.splice(0, 1, 'April')) : ('false');
    let may = endMonths[0] === '05' ? (endMonths.splice(0, 1, 'May')) : ('false');
    let jun = endMonths[0] === '06' ? (endMonths.splice(0, 1, 'June')) : ('false');
    let july = endMonths[0] === '07' ? (endMonths.splice(0, 1, 'July')) : ('false');
    let aug = endMonths[0] === '08' ? (endMonths.splice(0, 1, 'August')) : ('false');
    let sep = endMonths[0] === '09' ? (endMonths.splice(0, 1, 'September')) : ('false');
    let oct = endMonths[0] === '10' ? (endMonths.splice(0, 1, 'October')) : ('false');
    let nov = endMonths[0] === '11' ? (endMonths.splice(0, 1, 'November')) : ('false');
    let dec = endMonths[0] === '12' ? (endMonths.splice(0, 1, 'December')) : ('false');

    let endMonth = endMonths.join();

    console.log('start month', endMonth);


    /* ----------------------------------------------- */

    let splitOperations = incrementDate.split('-').reverse();

    // console.log('split first',splitOperations);

    let Januar = splitOperations[1] === '01' ? (splitOperations.splice(1, 1, 'January,')) : ('false');
    let Februar = splitOperations[1] === '02' ? (splitOperations.splice(1, 1, 'February,')) : ('false');
    let Marc = splitOperations[1] === '03' ? (splitOperations.splice(1, 1, 'March,')) : ('false');
    let Apri = splitOperations[1] === '04' ? (splitOperations.splice(1, 1, 'April,')) : ('false');
    let Mayss = splitOperations[1] === '05' ? (splitOperations.splice(1, 1, 'May,')) : ('false');
    let Juness = splitOperations[1] === '06' ? (splitOperations.splice(1, 1, 'June,')) : ('false');
    let Julyss = splitOperations[1] === '07' ? (splitOperations.splice(1, 1, 'July,')) : ('false');
    let Augusts = splitOperations[1] === '08' ? (splitOperations.splice(1, 1, 'August,')) : ('false');
    let Septembers = splitOperations[1] === '09' ? (splitOperations.splice(1, 1, 'September,')) : ('false');
    let Octobers = splitOperations[1] === '10' ? (splitOperations.splice(1, 1, 'October,')) : ('false');
    let Novembers = splitOperations[1] === '11' ? (splitOperations.splice(1, 1, 'November,')) : ('false');
    let Decembers = splitOperations[1] === '12' ? (splitOperations.splice(1, 1, 'December,')) : ('false');

    let incremenDate = splitOperations.join(' '); 

    console.log("incre date",incremenDate);



    let earningss = [];
    let crossPayTotal = [];

    let oldCtc = [];
    let crossPayTotals = [];

    data.forEach(x => band === x.band ?
      arr.forEach(y => {
        if (x.paymentName == y) {
          let newData = Math.round(newSalary * x.paymentPercentage / 100);
          crossPayTotal.push(newData);
          earningss.push({ paymentName: x.paymentName, amount: newData })
          return;
        }
      })
    :null);
    console.log('Earnings array', earningss);
    let crossPay = 0;
    for (let i = 0; i < crossPayTotal.length; i++) {
      crossPay += crossPayTotal[i];
    }

    data.forEach(x => band === x.band ?
      arrs.forEach(y => {
        if (x.paymentName == y) {
          let newData = Math.round(props.payment.ctc * x.paymentPercentage / 100);
          crossPayTotals.push(newData);
          oldCtc.push({ paymentName: x.paymentName, amount: newData })
          return;
        }
      })
   :null );
    console.log('Old Earnings array', oldCtc);
    let crossPays = 0;
    for (let i = 0; i < crossPayTotals.length; i++) {
      crossPays += crossPayTotals[i];
    }

    /* ---------------------------- */


    axios.post('https://employee.shinelogics.com/api/increment/save', {
      newSalary: newSalary,
      startMonth,startMonth,
      endMonth:endMonth,
      earningss: earningss,
      generateDate: generateDate,
      incremenDate:incremenDate,
      oldCtc: oldCtc,
      data: props.payment,
    })/* .then(()=>{
      console.log("succesfully submitted");
    }) 
    .catch(() =>{
      console.log("ERR");
    }) */
      .then(response => { setNewData(response.data); console.log("newdata", response); },

        setTimeout(() => {
          console.log('worledddddddd');

          const id = props.payment._id;

          axios.get(`https://employee.shinelogics.com/api/increment/getByEmpId/${id}`)
            .then(response => { console.log("responseeeeeeeeeeee", response.data); setFinest(response.data); pdfmakedownload(response.data) })
            .catch(err => {
              console.log(err);
            })
        }, 1000))
 
        setTimeout(()=>{
          window.location.reload(false)
          },2000)

      .catch(err => {
        console.log(err);
      })


    // console.log("nnnnnnnn",newData);
    // console.log("nnnnnnnn final",salary);



    // console.log("newSalary",newSalary, "from",from, "to",to, "earningss",earningss, "date",date," props.payment.ctc", props.payment.ctc ,"oldCtc",oldCtc);
  }

  /* ------------------------------------------------ */

  /* const func = (id) => { 
        axios.get(`https://employee.shinelogics.com/api/increment/getByEmpId/${id}`)
               .then(response => response.json())
               .then(salary => {setSalary(salary);console.log('final',salary);});        
       }; */

  /* setTimeout(() =>{
   func()
 },4000) */

  // console.log(salary);





  return (

    <>
      <form onSubmit={handleSubmit}>
        <div className='formfield'>
        <div className="newUserItem">
          <h5>Select the Band</h5>
        <select className="form-control"
        id='newtop'
          value={band}
          onChange={(e) => setBand(e.target.value)} >
          <option >--Select the band--</option>
          <option value="Band1">Band1</option>
          <option value="Band2">Band2</option>
          <option value="Band3">Band3</option>
          <option value="Band4">Band4</option>
        </select>
        </div>
          <div className="newUserItem">
            {/* <label>New salary</label> */}
            <h5>Earnings for New Salary:</h5>
            <MultiSelect className="formcontrol"
              options={earningss}
              value={select}
              onChange={(event) => setSelect(event)}
            />
          </div>
          <br />
          <h5>Enter the New Salary/Ctc</h5>
          <div class="form-group">
            <TextField id="outlined-basic" className='formfields' label="New salary Ctc" variant="outlined" value={newSalary} type="number" onChange={(e) => setNewSalary(e.target.value)} />
          </div>
          <h5>Previos Salary/Ctc: {props.payment.ctc}</h5>
          <br/>
          <h5>Earnings for Previous Salary:</h5>
          <div className="newUserItem">
            <MultiSelect className="formcontrol"
              placeholder='Earnings'
              options={earningss}
              value={selects}
              onChange={(event) => setSelects(event)}
            />
          </div>
          <br/>
          <h5>Generate Date:</h5>
          <div class="form-group">
            <TextField id="outlined-basic" className='formfields' label="" variant="outlined" value={date} type="date" onChange={(e) => setDate(e.target.value)} />
          </div>
          <h5>Previos Salary starting month:</h5>
          <div class="form-group">
            <TextField id="outlined-basic" className='formfields' label="" variant="outlined" value={from} type="month" onChange={(e) => setFrom(e.target.value)} />
          </div>
          <h5>Previos Salary Ending month:</h5>
          <div class="form-group">
            <TextField id="outlined-basic" className='formfields' label="" variant="outlined" value={to} type="month" onChange={(e) => setTo(e.target.value)} />
          </div>
          <h5>Increment Date:</h5>
          <div class="form-group">
            <TextField id="outlined-basic" className='formfields' label="" variant="outlined" value={incrementDate} type="date" onChange={(e) => setIncrementDate(e.target.value)} />
          </div>
         
          <br />
          <button type="submit" class="btn btn-primary"  /* onClick={() => { func(props.payment._id)}} */ /* onClick={() => { func(props.payment._id) }} */>Submit</button>
        </div>
      </form>
    </>
  )
}
export default Dropdown


