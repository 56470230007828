import React from "react";
// import './Popups.css'
 
const Popup = props => {
  // console.log('props',props);
  return (
    <div className="popup-boxs">
      <div className="boxs">
        <span className="close-icons" onClick={props.handleClose}>x</span>
        {props.content}
         {/* <div><h3>{props.payment.salary}</h3></div>  */}
      </div>
    </div>
  );
};
 
export default Popup;