import { useState, useEffect } from "react";
import "./forgotpassword.css";
import {Link} from "react-router-dom";
import Typography from '@mui/material/Typography';



function App() {
  const initialValues = { username: "", email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.username) {
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
  
    return errors;
  };

  return (
    <div className="lcontainer">
      {Object.keys(formErrors).length === 0 && isSubmit ? (
        <div className="ui message success">OTP send successfully to your Mail-ID</div>
      ) : (
        <pre>{JSON.stringify(formValues, undefined, 2)}</pre>
      )}

      <form onSubmit={handleSubmit}>
      
        <h3>Forgot Password </h3>
        <div className="ui divider"></div>
        <div className="ui form">
          
          <p>{formErrors.username}</p>
          <div className="field">
            <label>Email</label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />
          </div>
          <p>{formErrors.password}</p>
          <Typography >
                     <Link to={"/resetpassword"} >
                     <br></br>
                     <button className="fluid ui button blue">Send OTP</button>
                </Link>
                </Typography>
          
        </div>
      
      </form>
    </div>
  );
}

export default App;
