import React from 'react'
import axios from 'axios';
import { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@mui/material';
import { useParams, useHistory } from "react-router-dom";

const Addempoffer = () => {

  let history = useHistory();

  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [collegeName, setcollegeName] = useState("");
  const [internshipPeriod, setinternshipPeriod] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleSubmit = (event) => {

    console.log(name, position, collegeName, internshipPeriod,fromDate,toDate,);

    axios.post('https://employee.shinelogics.com/api/internship/save', {

      name: name,
      position: position,
      collegeName: collegeName,
      internshipPeriod: internshipPeriod,
      fromDate: fromDate,
      toDate: toDate,
      // ctc: ctc,

    })
      .then(() => {

        toast.success("Submitted Successfully", { position: toast.POSITION.BOTTOM_CENTER },setTimeout(()=>{
          history.push('/internship')},2000));


      })
      .catch(() => {
        toast.error("You left somefield empty", { position: toast.POSITION.BOTTOM_CENTER });
      })

    event.preventDefault();

  }


  return (
      <div className="user">
        <form onSubmit={handleSubmit} >
          <div class="form-row">
            <div class="col">
            <h5>Name</h5>
              <TextField type='text' className='textfiel' required id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
            </div>
            <div className="col">
            <h5>Position</h5>
              <TextField type='text' className='textfiel' required id="outlined-basic" label="position" variant="outlined" value={position} onChange={(event) => setPosition(event.target.value)} />
            </div>
          </div>
          <br />
          <div class="form-row">
            <div class="col">
              <h5>CollegeName</h5>
              <TextField className='textfiel' id="outlined-basic"  label="clientName" type='text' variant="outlined" value={collegeName} onChange={(event) => setcollegeName(event.target.value)} />
            </div>
            <div class="col">
              <h5>Internship Period</h5>
              <TextField className='textfiel' id="outlined-basic"  label="Internship Period" type='text' variant="outlined" value={internshipPeriod} onChange={(event) => setinternshipPeriod(event.target.value)} />
            </div>
          </div>
          <br />
          <div class="form-row">
            <div className="col">
            <h5>FromDate</h5>
              <TextField className='textfiel' id="outlined-basic" required label="" type='date' variant="outlined" value={fromDate} onChange={(event) => setFromDate(event.target.value)} />
            </div>
            <div className="col">
            <h5>ToDate</h5>
              <TextField className='textfiel' id="outlined-basic" required label="" type='date' variant="outlined" value={toDate} onChange={(event) => setToDate(event.target.value)} />
            </div>
          </div>
          <br />
          <div class="form-row">
            {/* <div class="col">
            <h5>Ctc</h5>
              <TextField className='textfiel' id="outlined-basic" label="ctc" variant="outlined" value={ctc} onChange={(event) => setCtc(event.target.value)} />
            </div> */}
            <div className="col">
            <button className="btn btn-primary">
            Create
          </button>
            <ToastContainer />
            </div>
          </div>
          <br />
        </form>
      </div>
  )
}

export default Addempoffer