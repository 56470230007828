import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import "../App.css";
import Home from "../pages/home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UserList from "../pages/userList/UserList";
import User from "../pages/user/User";
import NewUser from "../pages/newUser/NewUser";
import ProductList from "../pages/productList/ProductList";
import Product from "../pages/product/Product";
import NewProduct from "../pages/newProduct/NewProduct";
import Role from "../pages/role/role";
import Hr from "../pages/Hr/hr";
import HrData from "../pages/hrdata/hrdata";
import UserView from "../pages/user/UserView";
import ViewHr from "../pages/hrdata/ViewHr";
// import Main from "../pages/Payslip/Main"
import MainPay from "../pages/Payslip/Pay/MainPay";
import Employee from "../pages/Employee/Employee";
import Addemployee from "../pages/Employee/Addemployee";
import PaySlipmaster from "../pages/Master/PaySlipmaster";
import Relieve from "../pages/RelievingLetter/Relieve";
import Increment from "../pages/IncrementLetter/increment";
import Offer from "../pages/Offer/Offer";
import Offers from "../pages/OfferLetter/Offer";
// import AddOffer from "../pages/OfferLetter/AddOffer";
import OfferEmp from "../pages/Offer/OfferEmp"
import Addempoffer from "../pages/Offer/Addempoffer";
import EmpView from '../pages/Employee/EmpView'
import UpdateEmployee from "../pages/Employee/UpdateEmployee";
import Update from "../pages/Master/Update";
import UpdateEmpOffer from '../pages/Offer/UpdateEmpOffer'
import UpdateUser from "../pages/userList/UpdateUser";
import Invoice from "../pages/Invoice/Invoice";
import { GenerateInvoice } from "../pages/Invoice/GenerateInvoice";
import Perfomance from '../pages/Perfomance/Perfomance'
import Review from "../pages/Perfomance/Review";
import Internship from "../pages/Internship Letter/Internship";
import InternshipEmp from "../pages/Internship Letter/InternshipEmp";
import Addempintership from "../pages/Internship Letter/Addempintership";
import EmployeeHistory from "../pages/EmployeeHistory/EmployeeHistory";
import UpdateEmpIntership from "../pages/Internship Letter/UpdateEmpInternship";
import Masters from "../pages/Masters/Master"
// import ProtectedRouter from '../components/Auth/Protected'
// import EmployeeHome from "../EmployeeHome/EmployeeHome";
// import PdfMakes from "../pages/Payslip/PdfMake";
// import Pdf from "../components/pdf/Pdf";

function App() {

  return (
    
    <div className="App">
      <Router>
      <Topbar />
        <div className="containment">
          <Sidebar />
          <Switch>   
             <Route path="/home">
              <Home />
            </Route> 
            <Route path="/users">
              <UserList />
            </Route>
            {/* New file creation */}
            <Route path="/masters">
              <Masters />
            </Route>
            <Route path="/user/:id">
              <User />
            </Route>
            <Route path="/updateuser/:id">
              <UpdateUser />
            </Route>
            <Route path="/newUser">
              <NewUser />
            </Route>
            <Route path="/products">
              <ProductList />
            </Route>
            <Route path="/product/:productId">
              <Product />
            </Route>
            <Route path="/newproduct">
              <NewProduct />
              </Route>
              <Route path="/role">
              <Role />
            </Route>
            <Route path="/hr">
              <Hr />
            </Route>
            <Route path="/hrdata">
              <HrData/>
            </Route>
            <Route path="/userview/:id">
              <UserView/>
            </Route>
            <Route path="/empview/:id">
              <EmpView/>
            </Route>
            <Route path="/viewhr/:id">
              <ViewHr/>
            </Route>
            <Route path="/payslip">
             <MainPay/>
            </Route>
            <Route path="/employee">
              <Employee/>
            </Route>
            <Route path="/newemployee">
              <Addemployee/>
            </Route>
            <Route path="/updateEmp/:id">
              <UpdateEmployee/>
            </Route>
            <Route path="/master">
              <PaySlipmaster/>
            </Route>
            <Route path="/updateMaster/:id">
              <Update/>
            </Route>
            <Route path="/relievingletter">
              <Relieve />
            </Route>
            <Route path="/increletter">
              <Increment/>
            </Route>
            <Route path="/offer">
              <Offer/>
            </Route>
            <Route path="/joining">
              <Offers/>
            </Route>
            <Route path="/offeremp">
              <OfferEmp/>
            </Route>
            {/* <Route path="/Internshipemp">
              <InternshipEmp/>
            </Route> */}
            <Route path="/addempoffer">
              <Addempoffer/>
            </Route>
            <Route path="/updempoff/:id">
              <UpdateEmpOffer/>
            </Route>
            <Route path="/clientoffer">
              <Offer/>
            </Route>
            <Route path="/invoice">
              <Invoice/>
            </Route>
            <Route path="/geninvoice">
              <GenerateInvoice/>
            </Route>
            <Route path="/appraisal/:id">
              <Perfomance/>
            </Route>
            <Route path="/review/:id">
              <Review/>
            </Route>
            <Route path="/Internship"> 
              <Internship/>
            </Route>
            <Route path="/addempinternship">  .
              <Addempintership/>
            </Route>
            <Route path="/EmployeeHistory">  .
              <EmployeeHistory/>
            </Route>
            <Route path="/updateEmpintership/:id">
              <UpdateEmpIntership/>
            </Route>
          </Switch>
          </div>
      </Router> 
    </div>
  );
}

export default App;
