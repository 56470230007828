import React from 'react'
import { TextField } from '@mui/material';
import { useState, useEffect } from "react";
import Axios from 'axios';
import { useParams, useHistory } from "react-router-dom";

const UpdateEmpOffer = () => {

    const { id } = useParams();
  let history = useHistory();

  const[data,setData] = useState('');

    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [collegeName, setCollegeName] = useState("");
    const [internshipPeriod, setInternshipPeriod] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    // const [ctc, setCtc] = useState("");

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/offer/getById/${id}`, data)
            .then((response) => {
                setData(response.data);
                setName(response.data.name);
                setPosition(response.data.position);
                setCollegeName(response.data.collegeName);
                setFromDate(response.data.fromDate);
                setToDate(response.data.toDate);
                // setCtc(response.data.ctc);
                console.log("ressssssss", response.data);
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])


    const handleSubmit = (event) => {
        event.preventDefault()
        Axios.put(`https://employee.shinelogics.com/api/offer/update/${id}`, { name: name, position: position, collegeName: collegeName, fromDate: toDate }).then(() => {
            setData(data.map((val) => {
                return val._id === id ? {
                        _id: id, name: name, position: position, collegeName: collegeName, fromDate: fromDate,toDate: toDate
                    }
                    : val;
            }))
        })
        setTimeout(()=>{
        history.push('/updateEmpinternship')},2000)
    }
    return (
        <div className="user">
            <form onSubmit={handleSubmit} >
                <div class="form-row">
                    <div class="col">
                        <h5>Name</h5>
                        <TextField type='text' className='textfiel' id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
                    </div>
                    <div className="col">
                        <h5>Position</h5>
                        <TextField type='text' className='textfiel' id="outlined-basic" label="position" variant="outlined" value={position} onChange={(event) => setPosition(event.target.value)} />
                    </div>
                </div>
                <br />
                <div class="form-row">
                    <div class="col">
                        <h5>ClientName</h5>
                        <TextField className='textfiel' id="outlined-basic" label="clientName" variant="outlined" value={collegeName} onChange={(event) => setCollegeName(event.target.value)} />
                    </div>
                    <div class="col">
                        <h5>Internship Period</h5>
                        <TextField className='textfiel' id="outlined-basic" label="Internship Period" variant="outlined" value={internshipPeriod} onChange={(event) => setInternshipPeriod(event.target.value)} />
                    </div>
                </div>
                <br />
                <div class="form-row">
                <div className="col">
                        <h5>To Date</h5>
                        <TextField className='textfiel' id="outlined-basic" label="" type='date' variant="outlined" value={toDate} onChange={(event) => setToDate(event.target.value)} />
                    </div>
                    <div className="col">
                        <h5>From Date</h5>
                        <TextField className='textfiel' id="outlined-basic" label="" type='date' variant="outlined" value={fromDate} onChange={(event) => setFromDate(event.target.value)} />
                    </div>
                </div>
                <br />
                <div className="col">
                        <button className="btn btn-primary">
                            Create
                        </button>
                    </div>
                <br />
            </form>
        </div>
    )
}

export default UpdateEmpOffer