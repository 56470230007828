import React from 'react'
import { useState, useEffect } from 'react'
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import axios from 'axios'
import pdfmakedownload from "../OfferLetter/PdfContainer"
import TextField from '@mui/material/TextField';
import './Dropdown.css'

const Dropdown = (props) => {

  const [data, setData] = useState([]);
  const [earnings, setEarnings] = useState();
  const [select, setSelect] = useState([])
  const [genDate, setGenDate] = useState([])
  const [doj, setDoj] = useState([])

  const [newData, setNewData] = useState();
  const [finest, setFinest] = useState();


  const [band, setBand] = useState();

  useEffect((id) => {
    axios.get(`https://employee.shinelogics.com/api/payment/get`)
      .then((response) => {
        setData(response.data)
        let details = [];
        details = response.data;

        let earning = details.filter(x => band === x.band ? x.paymentType == 'Earnings' : null);
        // console.log(earning);
        let values = []
        earning.forEach(res => {
          values.push({ label: res.paymentName, value: res.paymentName })
        })
        setEarnings(values);
      })
      .catch(() => {
        console.log("ERR");
      })
  }, [band])

  const handleSubmit = (event, value) => {
    event.preventDefault();

    const arr = select.split(',');
    console.log(arr);

    /* ---------------------------------- */

    let splitOperation = genDate.split('-').reverse();

    console.log('split first',splitOperation);

    let January = splitOperation[1] === '01' ? (splitOperation.splice(1, 1, 'January,')) : ('false');
    let February = splitOperation[1] === '02' ? (splitOperation.splice(1, 1, 'February,')) : ('false');
    let March = splitOperation[1] === '03' ? (splitOperation.splice(1, 1, 'March,')) : ('false');
    let April = splitOperation[1] === '04' ? (splitOperation.splice(1, 1, 'April,')) : ('false');
    let Mays = splitOperation[1] === '05' ? (splitOperation.splice(1, 1, 'May,')) : ('false');
    let June = splitOperation[1] === '06' ? (splitOperation.splice(1, 1, 'June,')) : ('false');
    let Julys = splitOperation[1] === '07' ? (splitOperation.splice(1, 1, 'July,')) : ('false');
    let August = splitOperation[1] === '08' ? (splitOperation.splice(1, 1, 'August,')) : ('false');
    let September = splitOperation[1] === '09' ? (splitOperation.splice(1, 1, 'September,')) : ('false');
    let October = splitOperation[1] === '10' ? (splitOperation.splice(1, 1, 'October,')) : ('false');
    let November = splitOperation[1] === '11' ? (splitOperation.splice(1, 1, 'November,')) : ('false');
    let December = splitOperation[1] === '12' ? (splitOperation.splice(1, 1, 'December,')) : ('false');

    let generateDate = splitOperation.join(' '); 

    console.log("gen date",generateDate);

    /* ------------------------------------------------ */

    
    let dateOfJoin = doj.split('-').reverse();

    console.log('first',dateOfJoin);

    let Januarys = dateOfJoin[1] === '01' ? (dateOfJoin.splice(1, 1, 'January,')) : ('false');
    let Februarys = dateOfJoin[1] === '02' ? (dateOfJoin.splice(1, 1, 'February,')) : ('false');
    let Marchs = dateOfJoin[1] === '03' ? (dateOfJoin.splice(1, 1, 'March,')) : ('false');
    let Aprils = dateOfJoin[1] === '04' ? (dateOfJoin.splice(1, 1, 'April,')) : ('false');
    let Mayss = dateOfJoin[1] === '05' ? (dateOfJoin.splice(1, 1, 'May,')) : ('false');
    let Junes = dateOfJoin[1] === '06' ? (dateOfJoin.splice(1, 1, 'June,')) : ('false');
    let Julyss = dateOfJoin[1] === '07' ? (dateOfJoin.splice(1, 1, 'July,')) : ('false');
    let Augusts = dateOfJoin[1] === '08' ? (dateOfJoin.splice(1, 1, 'August,')) : ('false');
    let Septembers = dateOfJoin[1] === '09' ? (dateOfJoin.splice(1, 1, 'September,')) : ('false');
    let Octobers = dateOfJoin[1] === '10' ? (dateOfJoin.splice(1, 1, 'October,')) : ('false');
    let Novembers = dateOfJoin[1] === '11' ? (dateOfJoin.splice(1, 1, 'November,')) : ('false');
    let Decembers = dateOfJoin[1] === '12' ? (dateOfJoin.splice(1, 1, 'December,')) : ('false');

    let dateOfJoining = dateOfJoin.join(' '); 

    console.log("Doj",dateOfJoining);








    let earnings = [];
    let crossPayTotal = [];

    data.forEach(x => band === x.band ?
      arr.forEach(y => {
        if (x.paymentName == y) {
          let newData = Math.round(props.payment.ctc * x.paymentPercentage / 100);
          crossPayTotal.push(newData);
          earnings.push({ paymentName: x.paymentName, amount: newData })
          return;
        }
      })
    :null);
    console.log('Earnings array', earnings);

    axios.post('https://employee.shinelogics.com/api/employeeOffer/save', {
      earnings: earnings,
      dateOfJoining: dateOfJoining,
      generateDate: generateDate,
      data: props.payment
    })
      .then(response => { setNewData(response.data);  /* console.log("newdata",response);  */ },

        setTimeout(() => {
          console.log('worledddddddd');

          const id = props.payment._id;

          axios.get(`https://employee.shinelogics.com/api/employeeOffer/getByEmp/${id}`)
            .then(response => { console.log("responseeeeeeeeeeee", response.data); setFinest(response.data); pdfmakedownload(response.data) })
            .catch(err => {
              console.log(err);
            })
        }, 2000))

        setTimeout(()=>{
          window.location.reload(false)
          },1000)

      .catch(err => {
        console.log(err);
      })

  }

  return (
    <div>
      <form onSubmit={handleSubmit} >
      <div className='formfield'>
      <div className="newUserItem">
          <h5>Select the Band</h5>
        <select className="form-control"
        id='newtop'
          value={band}
          onChange={(e) => setBand(e.target.value)} >
          <option >--Select the band--</option>
          <option value="Band1">Band1</option>
          <option value="Band2">Band2</option>
          <option value="Band3">Band3</option>
          <option value="Band4">Band4</option>
        </select>
        </div>
        <h5>Earnings for Actual Salary:</h5>
        <div className="newUserItem">
          <MultiSelect
            options={earnings}
            value={select}
            onChange={(event) => setSelect(event)}
          />
        </div>
        <br/>
          <h5>Actual Salary:{props.payment.ctc}</h5>
        <br/>
        <h5>GenerateDate:</h5> 
        <div class="form-group">
          <TextField id="outlined-basic" className='formfields' label="" variant="outlined" value={genDate} type="date" onChange={(e) => setGenDate(e.target.value)} />
        </div>
        <h5>Excpt D.O.J:</h5>
        <div class="form-group">
          <TextField id="outlined-basic" className='formfields' label="" variant="outlined" value={doj} type="date" onChange={(e) => setDoj(e.target.value)} />
        </div>
        <div>
          <button className="newUserButton" /* onClick={() => { func(props.payment._id) }} */>Submit</button>
        </div>
       </div>
      </form>
    </div>
  )
}

export default Dropdown