import React, { useState,useEffect } from 'react'
import TextField from '@mui/material/TextField';
import pdfmakedownload from './PdfContainer'
import axios from 'axios';
import { Link, useParams } from "react-router-dom";

const Perfomance = (props) => {

    const [durationFrom, setDurationFrom] = useState('');
    const [durationTo, setDurationTo] = useState('');
    const [projectName, setProjectName] = useState('');
    const [clientName, setClientName] = useState('');
    const [managerName, setManagerName] = useState('');
    const [headName, setHeadName] = useState('');
    const [hrName, setHrName] = useState('');
    const [objective, setObjective] = useState('');


    const [newData, setNewData] = useState('');
    const [finest, setFinest] = useState('');


    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`https://employee.shinelogics.com/api/employee/getId/${id}`, data)
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])

    const handleSubmit = (event) => {
        console.log(durationFrom, durationTo, projectName, clientName, managerName, headName, hrName );
        event.preventDefault();

        axios.post('https://employee.shinelogics.com/api/performance/save', {
            durationFrom: durationFrom,
            durationTo: durationTo,
            projectName: projectName,
            clientName: clientName,
            managerName: managerName,
            headName: headName,
            hrName: hrName,
            objective:objective,
            data: data
        })
        .then(response => { setNewData(response.data); console.log("newdata", response); },

        setTimeout(() => {
          console.log('worledddddddd');

          const id = data._id;

          axios.get(`https://employee.shinelogics.com/api/performance/getById/${id}`)
            .then(response => { console.log("responseeeeeeeeeeee", response.data); setFinest(response.data); pdfmakedownload(response.data) })
            .catch(err => {
              console.log(err);
            })
        }, 1000))
 
        setTimeout(()=>{
            console.log('lll');
                },2000)

      .catch(err => {
        console.log(err);
      })

    }
    return (
        <div className='user'>
            <div className='card'>
                <form onSubmit={handleSubmit}>
                    <div id='comp'>
                        <div class="form-row">
                            <div class="col">
                                <h5>Duration Time From</h5>
                                <TextField type='date' className='textfiel' id="outlined-basic" label="" variant="outlined" value={durationFrom} onChange={(event) => setDurationFrom(event.target.value)} />
                            </div>
                            <div className="col">
                                <h5>Duration Time To</h5>
                                <TextField type='date' className='textfiel' id="outlined-basic" label="" variant="outlined" value={durationTo} onChange={(event) => setDurationTo(event.target.value)} />
                            </div>
                        </div>
                        <br />
                        <div class="form-row">
                            <div class="col">
                                <h5>Employee project Name</h5>
                                <TextField type='text' className='textfiel' id="outlined-basic" label="Employee project Name" variant="outlined" value={projectName} onChange={(event) => setProjectName(event.target.value)} />
                            </div>
                            <div className="col">
                                <h5>Employee Client Name</h5>
                                <TextField type='text' className='textfiel' id="outlined-basic" label="Employee Client Name" variant="outlined" value={clientName} onChange={(event) => setClientName(event.target.value)} />
                            </div>
                        </div>
                        <br />
                        <div class="form-row">
                            <div class="col">
                                <h5>Manager Name</h5>
                                <TextField type='text' className='textfiel' id="outlined-basic" label="Manager Name" variant="outlined" value={managerName} onChange={(event) => setManagerName(event.target.value)} />
                            </div>
                            <div className="col">
                                <h5>Head Name</h5>
                                <TextField type='text' className='textfiel' id="outlined-basic" label="Head Name" variant="outlined" value={headName} onChange={(event) => setHeadName(event.target.value)} />
                            </div>
                        </div>
                        <br />
                        <div class="form-row">
                            <div class="col">
                                <h5>Hr Name</h5>
                                <TextField type='text' className='textfiel' id="outlined-basic" label="Hr Name" variant="outlined" value={hrName} onChange={(event) => setHrName(event.target.value)} />
                            </div>
                            <div className="col">
                            </div>
                        </div>
                        <br />
                        <div class="form-row">
                            <div class="col">
                                <h5>Objective</h5>
                                <TextField type='text' multiline rows={2} className='textfiel' id="outlined-basic" label="objective" variant="outlined" value={objective} onChange={(event) => setObjective(event.target.value)} />
                            </div>
                        </div>
                        <br />
                        <div class="form-row">
                            <div className="col">
                                <button className="btn btn-primary">
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Perfomance