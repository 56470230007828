import React, { useState } from "react";
import axios from "axios";
import "./signup.css";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [userRole, setUserRole] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataWithRole = {
      ...formData,
      role: userRole,
    };

    console.log("Form submitted:", formDataWithRole);

    const apiUrl = "https://employee.shinelogics.com/api/user/save";
    axios
      .post(apiUrl, formDataWithRole)
      .then((response) => {
        console.log("API response:", response.data);
        // Perform any additional actions or handle the response as needed
      })
      .catch((error) => {
        console.error("API error:", error);
        // Handle API errors here
      });
  };

  return (
    <form className="registration-form" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
      </div>
      {/* <div>
        <label htmlFor="role">Role:</label>
        <select
          id="role"
          name="role"
          value={userRole}
          onChange={(e) => setUserRole(e.target.value)}
          required
        >
          <option value="">Select a role</option>
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </select>
      </div> */}
      <button type="submit">Register</button>
    </form>
  );
};

export default RegistrationForm;
