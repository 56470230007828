import React, { useState, useEffect } from 'react'
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import axios from 'axios'
import './Dropdown.css'
// import AddIcon from '@mui/icons-material/Add';
// import MinimizeIcon from '@mui/icons-material/Minimize';
import pdfmakedownload from "../Pay/PdfContainer"
import { CgAddR } from 'react-icons/cg';
import { AiOutlineMinusSquare } from 'react-icons/ai';
import { TextField } from '@mui/material';
// import TextField from '@material-ui/core/TextField';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useParams, useHistory } from "react-router-dom";

const Dropdown = (props) => {

  const { id } = useParams();

  let history = useHistory();

  const [value, setvalue] = useState('')


  const dropStyle = {
    height: "100%"
  }

  const [band, setBand] = useState();

  const [data, setData] = useState([]);
  const [earnings, setEarnings] = useState();
  const [deductions, setDeductions] = useState();
  const [calculateEarn, setCalculateEarn] = useState([]);
  const [calculateDeduct, setCalculateDeduct] = useState([]);
  const [amount, setAmount] = useState([]);
  const [payment, setPayment] = useState(props.payment);
  const [select, setSelect] = useState([])
  const [selected, setSelected] = useState([]);
  const [salaryDetail, setSalaryDetail] = useState([]);
  const [lossOfPay, setLossOfPay] = useState(0);

  const [salary, setSalary] = useState([]);
  const [newData, setNewData] = useState();
  const [finest, setFinest] = useState();


  const [month, setMonth] = useState();
  const [genDate, setGenDate] = useState();
  const [selectedDate, setSelectedDate] = useState();


  let ctcCalc = Math.round(props.payment.ctc / 12);

  console.log("ctcCalc", ctcCalc);

  useEffect((id) => {
    axios.get(`https://employee.shinelogics.com/api/payment/get`)
      .then((response) => {
        setData(response.data)
        let details = [];
        details = response.data

        console.log('ddddddddddddddd',data)

        let earning = details.filter(x => band === x.band ? x.paymentType == 'Earnings' : null);
        console.log(earning)

        let values = []
        earning.forEach(res => {
          values.push({ label: res.paymentName, value: res.paymentName })
        })
        setEarnings(values);

        console.log('vvvvvvvvvv',values)

        /* ---------- */

        let deduct = details.filter(x => band === x.band ? x.paymentType == 'Deduction' : null);
        // console.log(deduct)
        let valuess = []
        deduct.forEach(res => {
          valuess.push({ label: res.paymentName, value: res.paymentName })
        })
        setDeductions(valuess);
        console.log('vvvvsssss',valuess);


        /* ---------------- */

        let calculate = details.filter(x => band === x.band ? x.paymentType == 'Earnings' : null);
        // console.log(calculate);
        let val = [];
        let cal = calculate.forEach(res => {
          val.push({ value: res.paymentPercentage })
        })
        setCalculateEarn(val)
        console.log('ear val',val)

        const final = val.reduce((a, b) => a + b.value, 0);
        // console.log('test', final);

        /* ---------------- */

        let calculates = details.filter(x => band === x.band ? x.paymentType == 'Deduction' : null);
        // console.log(calculate);
        let vals = [];
        let cals = calculates.forEach(res => {
          vals.push({ value: res.paymentPercentage })
        })
        setCalculateDeduct(vals)
        console.log('ded val',vals);

        const finals = vals.reduce((a, b) => a + b.value, 0);
        // console.log(finals);

        const payFinal = final - finals;

      })
      .catch(() => {
        console.log("ERR");
      })
  }, [band])



  const handleSubmit = (event, value) => {


    event.preventDefault();

    const arr = select.split(',');
    console.log(arr);
    const arrs = selected.split(',');
    console.log(arrs);

    let earnings = [];
    let deduction = [];
    let crossPayTotal = [];
    let DeductionTotal = [];

    /* --------------------------Earnings calculation---------------------------------------- */

    data.forEach(x =>   band === x.band ?
      arr.forEach(y => {
        if (x.paymentName == y) {
          let newData = Math.round(ctcCalc * x.paymentPercentage / 100);
          crossPayTotal.push(newData);
          earnings.push({ paymentName: x.paymentName, amount: newData })
          return;
        }
      })
    :null )

    console.log('Earnings array', earnings);
    let crossPay = 0;
    for (let i = 0; i < crossPayTotal.length; i++) {
      crossPay += crossPayTotal[i];
    }
    console.log("crosspay total", crossPay);

    /* -------------------Loss of pay calculation------------------------------------- */

    let mon = selectedDate.getMonth();
    let year = selectedDate.getFullYear();
    let selection = new Date(year, mon + 1, 0).getDate();

    console.log('selection', selection);

    /* ----- */

    var total = [];

    for (var i = 1; i <= selection; i++) {    //looping through days in month
      var newDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), i)
      if (newDate.getDay() == 0) {   //if Sunday
        total.push(i);
      }
      if (newDate.getDay() == 6) {   //if Saturday
        total.push(i);
      }

    }
    let satSun = total.length
    console.log('$$$$$$$$$$', satSun);

    let totalWDays = selection - satSun;

    console.log('totalWDays', totalWDays)


    /* --- */
    let lossOfPays = Math.round(ctcCalc / selection * lossOfPay)
    console.log("lossofpay", lossOfPays);

    /* -- */

    let personWorkingDays = totalWDays - lossOfPay

    console.log("personWorkingDays", personWorkingDays);

    /* -----------------------Deduction calculation---------------------------------------- */

    data.forEach(x =>  band === x.band ?
      arrs.forEach(y => {
        if (x.paymentName == y) {
          let newDatas = Math.round(ctcCalc * x.paymentPercentage / 100);
          DeductionTotal.push(newDatas);
          deduction.push({ paymentName: x.paymentName, amount: newDatas });
          return
        }
      })
    :null)
    console.log('Duduction array', deduction);
    let deduct = 0;
    for (let i = 0; i < DeductionTotal.length; i++) {
      deduct += DeductionTotal[i] + lossOfPays;
    }
    console.log("deduction total", deduct);

    /* -------------------Netpay calculation------------------------------ */

    let netPay = crossPay - deduct;
    console.log("Net pay:", netPay);

    /* ---------------------- */


    /* 
        var today = new Date();
        var dateTime = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        console.log("dateTime",dateTime); */

    console.log('month', month);
    console.log('selectedDate', selectedDate);

    let months = month.split('-').reverse();
    console.log('month#####', months);
    let Jan = months[0] === '01' ? (months.splice(0, 1, 'January')) : ('false');
    let Feb = months[0] === '02' ? (months.splice(0, 1, 'February')) : ('false');
    let Mar = months[0] === '03' ? (months.splice(0, 1, 'March')) : ('false');
    let Apr = months[0] === '04' ? (months.splice(0, 1, 'April')) : ('false');
    let May = months[0] === '05' ? (months.splice(0, 1, 'May')) : ('false');
    let Jun = months[0] === '06' ? (months.splice(0, 1, 'June')) : ('false');
    let July = months[0] === '07' ? (months.splice(0, 1, 'July')) : ('false');
    let Aug = months[0] === '08' ? (months.splice(0, 1, 'August')) : ('false');
    let Sep = months[0] === '09' ? (months.splice(0, 1, 'September')) : ('false');
    let Oct = months[0] === '10' ? (months.splice(0, 1, 'October')) : ('false');
    let Nov = months[0] === '11' ? (months.splice(0, 1, 'November')) : ('false');
    let Dec = months[0] === '12' ? (months.splice(0, 1, 'December')) : ('false');

    // console.log("jan",jan);

    let genDates = genDate.split('-').reverse().join('-');
    console.log('gedate****', genDates);
    console.log('*****', months);
    let monthss = months.join(' ');
    console.log('Final', monthss);





    axios.post('https://employee.shinelogics.com/api/salary/save', {
      earnings: earnings,
      deduction: deduction,
      crossPay: crossPay,
      deduct: deduct,
      netPay: netPay,
      lossOfPays: lossOfPays,
      lossOfPay: lossOfPay,
      genDates: genDates,
      monthss: monthss,
      totalWDays: totalWDays,
      personWorkingDays: personWorkingDays,
      data: props.payment,
    })
      .then(response => { setNewData(response.data); console.log("newdata", response); },

        setTimeout(() => {
          console.log('worledddddddd');

          const id = props.payment._id;

          axios.get(`https://employee.shinelogics.com/api/salary/byEmpId/${id}`)
            .then((response) => { console.log("responseeeeeeeeeeee", response); 
             setFinest(response.data); pdfmakedownload(response.data)  
          })
            .catch(err => {
              console.log(err);
            })
        }, 2000))

      .catch(err => {
        console.log(err);
      })


  }





  return (

    <>
      <form onSubmit={handleSubmit} className="newUserForm">
      <div className='formfield'>
          <div className="newUserItem">
          <h5>Select the Band</h5>
        <select className="form-control"
          id='newtop'
          value={band}
          onChange={(e) => setBand(e.target.value)} >
          <option >--Select the band--</option>
          <option value="Band1">Band1</option>
          <option value="Band2">Band2</option>
          <option value="Band3">Band3</option>
          <option value="Band4">Band4</option>
        </select>
        </div>
        </div>
        <div className='formfield'>
          <div className="newUserItem">
            <h5>Earnings</h5>
            <MultiSelect className="formcontrol"
              options={earnings}
              value={select}
              onChange={(event) => setSelect(event)}
            />
          </div>

          <div className="newUserItem">
            <h5>Deduction</h5>
            <MultiSelect className="formcontrol"
              options={deductions}
              value={selected}
              onChange={(event) => setSelected(event)}
            />
          </div>
          <div className="newUserItem">
            <h5>Month</h5>
            <TextField className='formfields' id="outlined-basic" type='month' label="" variant="outlined" value={month} onChange={(event) => setMonth(event.target.value)} />
          </div>
          <div className="newUserItem">
            <h5>Generate Date</h5>
            <TextField className='formfields' id="outlined-basic" label="" variant="outlined" type="date" name="begin" /* placeholder="DD-MM-YYYY" */ /* dateFormat='dd-mm-yy' */ min="1997-01-01" max="2030-12-31" value={genDate} onChange={(event) => setGenDate(event.target.value)} />
          </div>
          <div className="newUserItem">
            <h5>Total working Day for the month</h5>
            <DatePicker className='datePick' id='datepickers' dateFormat="Pp" selected={selectedDate} onChange={date => setSelectedDate(date)} />
          </div>
          <div className="newUserItem">
            <h5>Actual Salary:{ctcCalc}</h5>
          </div>

          <div className="lossofpay">
            <h5>Loss of Pay/Days:
              <AiOutlineMinusSquare className='MinimizeIcon' onClick={() => setLossOfPay(lossOfPay - 1)}></AiOutlineMinusSquare>
              {lossOfPay}
              <CgAddR className='AddIcon' onClick={() => setLossOfPay(lossOfPay + 1)}></CgAddR>
            </h5>
          </div>
          <div >
            <button className="newUserButton" >Submit</button>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </form>

    </>
  )
}
export default Dropdown


