import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@material-ui/icons";
import "./user.css";
import Axios from 'axios';
import { useState,useEffect} from "react";
import { useParams,useHistory } from "react-router-dom";
export default function User() { 
 const { id } = useParams();
 let history = useHistory();
  const [data, setData] = useState([]);
  const [nname, setName]=useState("");
  const [nmobile, setMobile]=useState("");
  const [nemail,setEmail]=useState(""); 

  const firstChange = (e) =>{ 
    setName(e.target.value);
    
  }   

  const secondChange = (e) =>{ 
    setEmail(e.target.value);
    
  }   

  const thirdChange = (e) =>{ 
    setMobile(e.target.value);
    
  }   
  useEffect(()=>{
    Axios.get(`https://employee.shinelogics.com/api/user/getid/${id}`,data)
    .then((response)=>{
      setData(response.data) 
      setName(response.data.name);
      setEmail(response.data.email);
      setMobile(response.data.mobile); 
    })
    .catch(()=>{
      console.log("ERR");
    })
  },[])

  const handleSubmit =(event) =>{
    console.log("***********");
    event.preventDefault()
   Axios.put(`https://employee.shinelogics.com/api/user/update/${id}`, {name:nname,email:nemail,mobile:nmobile}).then(()=>{
     setData(data.map((val)=>{
       return val._id === id
        ? {_id:id, name:nname, email:nemail, mobile:nmobile}
        : val;
     }))
   })
   history.push('/users');
 }

  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Edit User</h1>
        
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <div className="userShowTopTitle">
              <span className="userShowUsername">{data.name}</span>
              <span className="userShowUserTitle">Full-Stack Developer</span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Account Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">{data.name}</span>
            </div>
            <div className="userShowInfo">
              <CalendarToday className="userShowIcon" />
              <span className="userShowInfoTitle">12/05/1998</span>
            </div>
            <span className="userShowTitle">Contact Details</span>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitle">{data.mobile}</span>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitle">{data.email}</span>
            </div>
            <div className="userShowInfo">
              <LocationSearching className="userShowIcon" />
              <span className="userShowInfoTitle">Tamilnadu | India</span>
            </div>
          </div>
        </div>
       <div>  
        <div className="userUpdate">
          <span className="userUpdateTitle">Edit</span>
          <form onSubmit={handleSubmit}  className="userUpdateForm">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Username</label>
                <input
                  type="text"         
                  placeholder="deva"         
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Full Name</label>
                <input
                  type="text"
                  name="name"
                  value={nname} onChange={firstChange}
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  value={nemail} onChange={secondChange}              
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Phone</label>
                <input
                  type="number"
                  name="mobile"
                  value={nmobile} onChange={thirdChange}  
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="Tamilnadu | India"
                  className="userUpdateInput"
                />
              </div>
            </div>
            <div className="userUpdateRight">
              <div className="userUpdateUpload">
                <img
                  className="userUpdateImg"
                  src="https://scontent.fmaa6-1.fna.fbcdn.net/v/t1.18169-9/18274853_1880146988893540_2114957191276995375_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=D_9Nz8yr7HQAX8KCqxQ&_nc_ht=scontent.fmaa6-1.fna&oh=00_AT-qLxhM7FZJmE0Lmz5OysNXh3h3viBY_JASkH8K_-NHKg&oe=61E9C686"
                  alt=""
                />
                <label htmlFor="file">
                  <Publish className="u\G erUpdateIcon" />
                </label>
                <input type="file" id="file" style={{ display: "none" }} />
              </div>
               <button className="userUpdateButton">Update</button>
            </div>
          </form>
        </div>
      </div>  
  </div> 
    </div>
  );
}
