import React from 'react'
import { useState } from 'react';
import { TextField } from '@mui/material';

export const GenerateInvoice = () => {

    const [companyName, setCompanyName]=useState("");
    const [address, setAddress]=useState("");
    const [gst, setGst]=useState("");
    const [taxNo, setTaxNo]=useState("");
    const [fCompanyName, setFCompanyName]=useState("");
    const [fAddress, setFAddress]=useState("");
    const [fGst, setFGst]=useState("");
    const [fTaxNo, setFTaxNo]=useState("");
    const [from, setFrom]=useState("");
    const [to, setTo]=useState("");
    const [consultant, setConsultant]=useState("");
    const [twd, setTwd]=useState("");
    const [nodw, setNodw]=useState("");
    const [rate, setRate]=useState("");

    const handleSubmit = () =>{
        console.log('KKKK')
    }

    return (
            <div className='user'>
                <form onSubmit={handleSubmit}>
                    <h4>TO</h4>
                    <br/>
                    <div class="form-row">
                        <div class="col">
                            <h5>CompanyName</h5>
                            <TextField type='text' className='textfiel' id="outlined-basic" label="CompanyName" variant="outlined" value={companyName} onChange={(event) => setCompanyName(event.target.value)} />
                        </div>
                        <div className="col">
                            <h5>Address</h5>
                            <TextField type='text' className='textfiel' id="outlined-basic" label="Address" variant="outlined" value={address} onChange={(event) => setAddress(event.target.value)} />
                        </div>
                    </div>
                    <br />
                    <div class="form-row">
                        <div class="col">
                            <h5>GST</h5>
                            <TextField className='textfiel' id="outlined-basic" label="GST" variant="outlined" value={gst} onChange={(event) => setGst(event.target.value)} />
                        </div>
                        <div className="col">
                            <h5>Tax.No</h5>
                            <TextField className='textfiel' id="outlined-basic" label="Tax.No" variant="outlined" value={taxNo} onChange={(event) => setTaxNo(event.target.value)} />
                        </div>
                    </div>
                    <br/>
                    <h4>From</h4>
                    <br/>
                    <div class="form-row">
                        <div class="col">
                            <h5>CompanyName</h5>
                            <TextField type='text' className='textfiel' id="outlined-basic" label="CompanyName" variant="outlined" value={fCompanyName} onChange={(event) => setFCompanyName(event.target.value)} />
                        </div>
                        <div className="col">
                            <h5>Address</h5>
                            <TextField type='text' className='textfiel' id="outlined-basic" label="Address" variant="outlined" value={fAddress} onChange={(event) => setFAddress(event.target.value)} />
                        </div>
                    </div>
                    <br />
                    <div class="form-row">
                        <div class="col">
                            <h5>GST</h5>
                            <TextField className='textfiel' id="outlined-basic" label="GST" variant="outlined" value={fGst} onChange={(event) => setFGst(event.target.value)} />
                        </div>
                        <div className="col">
                            <h5>Tax.No</h5>
                            <TextField className='textfiel' id="outlined-basic" label="Tax.No" variant="outlined" value={fTaxNo} onChange={(event) => setFTaxNo(event.target.value)} />
                        </div>
                    </div>
                    <br/>
                    <div class="form-row">
                        <div class="col">
                            <h5>Billing Date from</h5>
                            <TextField className='textfiel' id="outlined-basic" label="From" variant="outlined" value={from} onChange={(event) => setFrom(event.target.value)} />
                        </div>
                        <div className="col">
                            <h5>Billing Date To</h5>
                            <TextField className='textfiel' id="outlined-basic" label="To" variant="outlined" value={to} onChange={(event) => setTo(event.target.value)} />
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <h4>Details:</h4>
                    <div class="form-row">
                        <div class="col">
                            <h5>Consultant Name</h5>
                            <TextField className='textfiel' id="outlined-basic" label="Consultant Name" variant="outlined" value={consultant} onChange={(event) => setConsultant(event.target.value)} />
                        </div>
                        <div className="col">
                            <h5>Total Working days</h5>
                            <TextField className='textfiel' id="outlined-basic" label="Total Working days" variant="outlined" value={twd} onChange={(event) => setTwd(event.target.value)} />
                        </div>
                    </div>
                    <br/>
                    <div class="form-row">
                        <div class="col">
                            <h5>No of days working</h5>
                            <TextField className='textfiel' id="outlined-basic" label="No of days working" variant="outlined" value={nodw} onChange={(event) => setNodw(event.target.value)} />
                        </div>
                        <div className="col">
                            <h5>Rate</h5>
                            <TextField className='textfiel' id="outlined-basic" label="Total Working days" variant="outlined" value={rate} onChange={(event) => setRate(event.target.value)} />
                        </div>
                    </div>
                    
                </form>
            </div>
    )
}
