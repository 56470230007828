import "./featuredInfo.css";
import { ArrowUpward } from "@material-ui/icons";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

export default function FeaturedInfo() {

  const[data ,setData] = useState([]);

  useEffect(()=>{
    axios.get(`https://employee.shinelogics.com/api/employee/count`)
    .then((response) => {
      setData(response.data)
    })
    .catch(() =>{
      console.log('Err')
    })
  },[])

  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Employee</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{data}</span>
          <span className="featuredMoneyRate">
            2+<ArrowUpward  className="featuredIcon"/>
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Applicants</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">4000</span>
          <span className="featuredMoneyRate">
            100+<ArrowUpward className="featuredIcon"/>
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Projects</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">800</span>
          <span className="featuredMoneyRate">
            10+ <ArrowUpward className="featuredIcon"/>
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
    </div>
  );
}
