import React from 'react'
 import {
    CalendarToday,
    LocationSearching,
    MailOutline,
    PermIdentity,
    PhoneAndroid
  } from "@material-ui/icons"; 
  import Axios from 'axios';
import { useState,useEffect} from "react";
  import { useParams } from "react-router-dom";

const ViewHr = () => {
    const { id } = useParams();   
     const [data, setData] = useState([]);
     
    useEffect(()=>{
        Axios.get(`https://employee.shinelogics.com/api/candidate/getid/${id}`,data)
        .then((response)=>{
          setData(response.data)
        })
        .catch(()=>{
          console.log("ERR");
        })
      },[])
    return (
        
         <div>
            <div className="vuser">
            <div className="userTitleContainer">
        <h1 className="userTitle">User Details</h1>
        
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <div className="userShowTopTitle">
              <span className="userShowUsername">{data.name}</span>
              <span className="userShowUserTitle">{data.livingLocation}</span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Account Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">{data.experience}+ years</span>
            </div>
            <div className="userShowInfo">
              <CalendarToday className="userShowIcon" />
              <span className="userShowInfoTitle">{data.createdOn}</span>
            </div>
            <span className="userShowTitle">Contact Details</span>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitle">{data.mobile}</span>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitle">{data.email}</span>
            </div>
            <div className="userShowInfo">
              <LocationSearching className="userShowIcon" />
              <span className="userShowInfoTitle">{data.prefferedLocation}</span>
            </div>
          </div>
        </div>
        </div>
        </div>
        </div>
    ) 
}



export default ViewHr
