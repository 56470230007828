import React,{useEffect,useState} from 'react'
import axios from 'axios';

const Dropdown = (props) => {

    const[data,setData] = useState([]);
             
    console.log('console',props.payment);

    const ids = props.payment._id;
    // console.log('ids',ids);
    console.log('data',data);

    useEffect(() =>{
        axios.get(`https://employee.shinelogics.com/api/appraisal/getAppraisal`)
          .then(response => setData(response.data))
          .catch(err => {
            console.log(err);
          })
        },[])

    //  const result = data.filter((x)=>{
    //      return x.employeeId === ids ? x : null
    //  })

    //  console.log('result',result)

  return (
        <div className="userlist">
                <div className="container">
                    <div className="py-4">
                        <div >
                            <table class="table border shadow">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" >Rev.Name</th>
                                        <th scope="col" >Hike per.</th>
                                        <th scope="col" >Slap</th>
                                        <th scope="col" >From Date</th>
                                        <th scope="col" >To Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    { data.filter((x)=>{
                                        return x.employeeId === ids ? x : null;
                                    }).map((val,index) => (
                                        <tr key={val.empId}>
                                            <td>{index + 1}</td>
                                            <td>{val.rName}</td>
                                            <td>{val.newHikePercentage+'%'}</td>
                                            <td>{val.slap}</td>
                                            <td>{val.fromDate}</td>
                                            <td>{val.toDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default Dropdown