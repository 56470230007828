import React, { useState, useEffect ,createContext} from 'react'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import axios from 'axios';
import pdfmakedownload from '../RelievingLetter/PdfContainer'; 
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DropDown.css'

const Dropdown = (props) => {

  const [perfomance, setPerfomance] = useState('')

  const [salary, setSalary] = useState([]);
  const [newData, setNewData] = useState();
  const [finest, setFinest] = useState();

  const [relieveDate, setRelieveDate] = useState();


  const dropStyle = {
    height: "100%"
  }

  console.log("incre",props.payment);



  const handleSubmit = (event) =>{
      event.preventDefault();
      console.log("Type",perfomance);
      console.log("Relieve Date",relieveDate);

    let relieve = relieveDate.getMonth()+1;
    console.log('relieve',relieve);

    let relieves = String(relieve).split(' ').map((relieve) =>{
      return Number(relieve)
    });

    console.log('relieves',relieves);


    
    let Jan = relieves[0] === 1 ? (relieves.splice(0, 1, 'January')) : ('false');
    let Feb = relieves[0] === 2 ? (relieves.splice(0, 1, 'February')) : ('false');
    let Mar = relieves[0] === 3 ? (relieves.splice(0, 1, 'March')) : ('false');
    let Apr = relieves[0] === 4 ? (relieves.splice(0, 1, 'April')) : ('false');
    let May = relieves[0] === 5 ? (relieves.splice(0, 1, 'May')) : ('false');
    let Jun = relieves[0] === 6 ? (relieves.splice(0, 1, 'June')) : ('false');
    let July = relieves[0] === 7 ? (relieves.splice(0, 1, 'July')) : ('false');
    let Aug = relieves[0] === 8 ? (relieves.splice(0, 1, 'August')) : ('false');
    let Sep = relieves[0] === 9 ? (relieves.splice(0, 1, 'September')) : ('false');
    let Oct = relieves[0] === 10 ? (relieves.splice(0, 1, 'October')) : ('false');
    let Nov = relieves[0] === 11 ? (relieves.splice(0, 1, 'November')) : ('false');
    let Dec = relieves[0] === 12 ? (relieves.splice(0, 1, 'December')) : ('false');

    console.log('final',relieves);

    let relieveDates = relieveDate.getDate()+' '+relieves.join(' ')+', '+relieveDate.getFullYear();

    console.log('finals',relieveDates);


      axios.post('https://employee.shinelogics.com/api/relieving/save',{

        data:props.payment,
        perfomance:perfomance,
        relieveDates:relieveDates


      })
      .then(response => { setNewData(response.data); console.log("incre newdata", response); },

        setTimeout(() => {
          console.log('worledddddddd');

          const id = props.payment._id;

          axios.get(`https://employee.shinelogics.com/api/relieving/getid/${id}`)
            .then(response => { console.log("responseeeeeeeeeeee", response.data); setFinest(response.data); pdfmakedownload(response.data) })
            .catch(err => {
              console.log(err);
            })
        }, 1000))
        setTimeout(()=>{
          window.location.reload(false)
          },2000)

      .catch(err => {
        console.log(err);
      })

  }

  return (
    <div>
    <form onSubmit={handleSubmit}>
                  <h5>Employee Perfomance:</h5>
                  <select class="form-select" aria-label="Default select example"
                    value={perfomance}
                    onChange={(e) => setPerfomance(e.target.value)}>
                    <option >--Select an Option--</option>
                    <option value="Poor"
                    >Poor</option>
                    <option value="Good"
                    >Good</option>
                    <option value="Excellent"
                    >Excellent</option>
                  </select>
                  <br/>
                  <h5>GenrateDate:</h5>
                  <DatePicker className='datePick' dateFormat="Pp" id='datepicker' selected={relieveDate} onChange={date => setRelieveDate(date)} />
                <button  className="newUserButton">submit</button>
                </form>
    </div>
  )
}
export default Dropdown


