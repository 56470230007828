import React from 'react'
import { TextField } from '@mui/material';
import { useState, useEffect } from "react";
import Axios from 'axios';
import { useParams, useHistory } from "react-router-dom";

const UpdateEmpOffer = () => {

    const { id } = useParams();
  let history = useHistory();

  const[data,setData] = useState('');

    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [clientName, setClientName] = useState("");
    const [joiningDate, setJoiningDate] = useState("");
    const [ctc, setCtc] = useState("");

    useEffect(() => {
        Axios.get(`https://employee.shinelogics.com/api/offer/getById/${id}`, data)
            .then((response) => {
                setData(response.data);
                setName(response.data.name);
                setPosition(response.data.position);
                setClientName(response.data.clientName);
                setJoiningDate(response.data.joiningDate);
                setCtc(response.data.ctc);
                console.log("ressssssss", response.data);
            })
            .catch(() => {
                console.log("ERR");
            })
    }, [])


    const handleSubmit = (event) => {
        event.preventDefault()
        Axios.put(`https://employee.shinelogics.com/api/offer/update/${id}`, { name: name, position: position, clientName: clientName, joiningDate: joiningDate, ctc: ctc }).then(() => {
            setData(data.map((val) => {
                return val._id === id ? {
                        _id: id, name: name, position: position, clientName: clientName, joiningDate: joiningDate, ctc: ctc
                    }
                    : val;
            }))
        })
        setTimeout(()=>{
        history.push('/offeremp')},2000)
    }
    return (
        <div className="user">
            <form onSubmit={handleSubmit} >
                <div class="form-row">
                    <div class="col">
                        <h5>Name</h5>
                        <TextField type='text' className='textfiel' id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
                    </div>
                    <div className="col">
                        <h5>Position</h5>
                        <TextField type='text' className='textfiel' id="outlined-basic" label="position" variant="outlined" value={position} onChange={(event) => setPosition(event.target.value)} />
                    </div>
                </div>
                <br />
                <div class="form-row">
                    <div class="col">
                        <h5>Client Name</h5>
                        <TextField className='textfiel' id="outlined-basic" label="College Name" variant="outlined" value={clientName} onChange={(event) => setClientName(event.target.value)} />
                    </div>
                    <div className="col">
                        <h5>Joining Date</h5>
                        <TextField className='textfiel' id="outlined-basic" label="" type='date' variant="outlined" value={joiningDate} onChange={(event) => setJoiningDate(event.target.value)} />
                    </div>
                </div>
                <br />
                <div class="form-row">
                    <div class="col">
                        <h5>Ctc</h5>
                        <TextField className='textfiel' id="outlined-basic" label="ctc" variant="outlined" value={ctc} onChange={(event) => setCtc(event.target.value)} />
                    </div>
                    <div className="col">
                        <button className="btn btn-primary">
                            Create
                        </button>
                    </div>
                </div>
                <br />
            </form>
        </div>
    )
}

export default UpdateEmpOffer